import React from 'react';
import { Form, Input, Button,Select,Spin,Result,message,Card,InputNumber,Modal,Switch,Popover,Popconfirm } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined, LoadingOutlined,CloseCircleFilled, MailOutlined,UploadOutlined,PlusCircleFilled } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import uuid from 'react-uuid'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';


var sacco= ''
var token= ''


class LoanApplicationForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    loan_type:'',
    account:'',
    savings_balance:0,
    loan_balance:0,
    loan_amount:0,
    loan_purpose:'',
    business_type:'',
    applicant_location:'',
    own_premises:'',
    legal_status_of_business:'',
    duration_in_business:'',
    collateral_one:'',
    collateral_one_value:0,
    collateral_two:'',
    collateral_two_value:0,
    loantypes:[],
    members:[],
    loantypeobj:{},
    accountobj:{},
    datasubmittedsuccessfully:false,
    datarequested:true,
    saccopackageobj:{},
    members:[],
    saccoboject:{},
    loanapplications:[],

    guarantorslist:[],
    guarantorformvisible:false,
    selectedguarantorobj:{},
    selectedguarantor:'',

    g_gua_savings:false,
    g_gua_shares:false,
    guar_sharesAmount:0,
    guar_savingsAmount:0,

    registeredbusinessdetails:{},
    regbusinessname:'',
    regbusinesscontact:'',
    regbusinesstinno:'',
    regbusinesslocation:'',
    regbizformvisible:false,

    othercol_model_visible:false,
    othercol_details:'',
    othercol_value:'',
    othercollateral_list:[]

  }



  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }


      axios.get(serverconfig.backendserverurl+`/api/loantypes/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            loantypes:res.data
          })
      })
  
      axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            members:res.data
          })
  
      })

      axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            loanapplications:res.data
          })
  
      })


      axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
      .then(res => {
          this.setState({
            saccoboject:res.data
          })
  
          //get the sacco package here
          axios.get(serverconfig.backendserverurl+`/api/packages/${res.data.package}`)
          .then(res => {
              this.setState({
                saccopackageobj:res.data
              })

              this.setState({datarequested:false})
          })
  
      })

  }

  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //check member savings period
  checksavingperiod=()=>{
    var m_savingperiod=Number(this.state.loantypeobj.minimum_savingsperiod_required)*30
    var dateof_joining=this.state.accountobj.DateOfJoining

    var datetwo=''
            
    var dateMomentObjectone = moment(dateof_joining, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjectone = dateMomentObjectone.toDate();

    var dateMomentObjecttwo = new Date(); // 1st argument - string, 2nd argument - format
    var dateObjecttwo =new Date()

    var date1 = dateObjectone;
    var date2 = dateObjecttwo;

    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    console.log(Difference_In_Days)

    //check if period is bigger than 
    if (Number(Difference_In_Days)<m_savingperiod){
      return false;
    }else{
      return true
    }

  }

  returnsavingsperiod=()=>{
    var Difference_In_Days=0
    var m_savingperiod=Number(this.state.loantypeobj.minimum_savingsperiod_required)*30
    var dateof_joining=this.state.accountobj.DateOfJoining

    var datetwo=''
            
    var dateMomentObjectone = moment(dateof_joining, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjectone = dateMomentObjectone.toDate();

    var dateMomentObjecttwo = new Date(); // 1st argument - string, 2nd argument - format
    var dateObjecttwo =new Date()

    var date1 = dateObjectone;
    var date2 = dateObjecttwo;

    var Difference_In_Time = date2.getTime() - date1.getTime();
    Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    console.log(Difference_In_Days)
    if(this.isEmptyObject(this.state.accountobj)===false || this.state.accountobj.DateOfJoining===null){
      return  Math.round(Difference_In_Days) ;
    }else{
      return 0;
    }
  }


 //calculate loan limit from guarantors
 calculta_guantaorslimit=()=>{
  var total=0

  if (this.state.guarantorslist.length>0){
    var xx_var=Number(this.state.loantypeobj.percentage_loan_limit_againstsavingsforguarantors)

    var g_s_s=0

    this.state.guarantorslist.map((item)=>{
      g_s_s+=Number(item.guar_savingsAmount)+Number(item.guar_sharesAmount)
    })

    total=g_s_s*xx_var
  }

  return total;
 }
  




  //calculate percentage loan limit against 
  calculate_loanlimit=()=>{
    var limit =0

    var savings=0
    var shares=0

    var xx_var=0

    if (this.isEmptyObject(this.state.accountobj)===false){
       savings=Number(this.state.accountobj.UserBalance)-Number(this.state.accountobj.savingsloan_security_balance)
       shares=Number(this.state.accountobj.sharesamount_balance)-Number(this.state.accountobj.shares_security_balance)

       xx_var=Number(this.state.loantypeobj.percentage_loan_limit_againstsavings)
    }

    limit=(savings+shares)*xx_var

    return limit+this.calculta_guantaorslimit();
  }


  calculatetotalof_guarantorsavingsavailable=()=>{
    var accounttotal=0
    var availabletotal=0

    if (this.state.g_gua_savings){
      accounttotal+=(Number(this.state.guar_savingsAmount))
    }

    if (this.state.g_gua_shares){
      accounttotal+=(Number(this.state.guar_sharesAmount))
    }

    availabletotal=Number(accounttotal)

    return availabletotal;

  }


   //check empty objecty
isEmptyObject=(obj)=>{
  for ( var name in obj ) {
    return false;
  }
  return true;
}






  //calculate total guarantor and members savings and shares
  calculateTotal_g_m_savingsandshares=()=>{
    var total=0
    var m_s_s=0

    if (this.isEmptyObject(this.state.accountobj)===false){
      m_s_s=(Number(this.state.accountobj.UserBalance)-Number(this.state.accountobj.savingsloan_security_balance))+(Number(this.state.accountobj.sharesamount_balance)-Number(this.state.accountobj.shares_security_balance))

    }
   
    //loop through out the guarantor list
    var g_s_s=0

    this.state.guarantorslist.map((item)=>{
      g_s_s+=Number(item.guar_savingsAmount)+Number(item.guar_sharesAmount)
    })
  
    total=m_s_s+g_s_s

    return total;
  }


  //two new conditions
  /* (this.state.loantypeobj.secured===true&&this.calculateTotal_g_m_savingsandshares()<Number(this.state.loan_amount)){
    message.error("Loan request amount cant be greater than total of members's and guarantors' savings and shares combined")  
  
  else if (this.calculateTotal_g_m_savingsandshares()<this.calculate_loanlimit())
    message.error("members's and guarantors' savings and shares combined  cant be less than the loan limit")  

   }*/

  
  //submit button pressed
  handleFormSubmit=(event) =>{

  if (this.checksavingperiod()==false){
    message.error("Saving period is less than the required period.")

  }else if (Number(this.state.accountobj.sharesnumber_balance)<Number(this.state.loantypeobj.minimum_no_ofshares_required)){
    message.error("Shares less than the required number.")

  }else if (Number(this.state.loan_amount)>this.calculate_loanlimit()){
    message.error("Loan amount cant go beyond the limit."+String(this.calculate_loanlimit()))

  }else if (this.state.loantypeobj.secured===true&&Number(this.state.guarantorslist.length)<Number(this.state.loantypeobj.minimum_member_guarantors)){
    message.error("Atleast "+String(this.state.loantypeobj.minimum_member_guarantors)+" guarantors neeeded")
  }
   else if (Number(this.state.loan_amount)> Number(this.state.loantypeobj.maximum_loan_amount)){
      message.error("Entered Loan Amount should be less or equal to "+String(this.state.loantypeobj.maximum_loan_amount))
  }
  
  else{

    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('loan_type', this.state.loan_type);
    form_data.append('account', this.state.account);
    form_data.append('savings_balance', this.state.accountobj.UserBalance);
    form_data.append('loan_balance', this.state.accountobj.loan_balance);
    form_data.append('loan_amount', this.state.loan_amount);
    form_data.append('loan_purpose', this.state.loan_purpose);
    form_data.append('applicant_location', this.state.applicant_location);
  
    form_data.append('othercollateral_list',JSON.stringify(this.state.othercollateral_list));
    form_data.append('guarantorslist',JSON.stringify(this.state.guarantorslist));
    form_data.append('application_loan_limit', this.calculate_loanlimit());

    form_data.append('sacco', sacco);
    
    axios.post(serverconfig.backendserverurl+'/api/loanapplications/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>         
    this.setState({datarequested:false})
,  this.setState({datasubmittedsuccessfully:true})
  )
  .catch(error => console.log(error)) 

  }

  }

  handleloan_typeChange= (e) => {
    this.setState({ loan_type: e});
    //get loan type details
    //get account details
    axios.get(`${serverconfig.backendserverurl}/api/loantypes/${e}`)
      .then(res => {  
          this.setState({loantypeobj: res.data})
      })
  }


  handleaccountChange= (e) =>{
    this.setState({ account: e});
    //get account details
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({accountobj: res.data})
          this.setState({savings_balance:res.data.UserBalance})
      })

      //check the last loan attributes
      let form_data = new FormData();
      form_data.append('account_id', e);

      axios.post(serverconfig.backendserverurl+'/customqueries/getlastloanattributes', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
          this.setState({loan_balance:res.data.user_info.totalLoanBalance})
        
      })
      .catch(error => console.log(error))

  } 
  
  
  
  handlesavings_balanceChange= (e) => this.setState({ savings_balance: e.target.value});
  handleloan_balanceChange= (e) => this.setState({ loan_balance: e.target.value});
  handleloan_amountChange= (e) => this.setState({ loan_amount: e});
  handleloan_purposeChange= (e) => this.setState({ loan_purpose: e.target.value});
  handlebusiness_typeChange= (e) => this.setState({ business_type: e.target.value});
  handleapplicant_locationChange= (e) => this.setState({ applicant_location: e.target.value});
  handleown_premisesChange= (e) => this.setState({ own_premises: e});
  handlelegal_status_of_businessChange= (e) =>{
    if (e==="Registered"){
      this.setState({regbizformvisible:true})
    }else{
      this.setState({regbizformvisible:false})
    }

    this.setState({ legal_status_of_business: e});

  } 


  handleduration_in_businessChange= (e) => this.setState({ duration_in_business: e.target.value});
  handlecollateral_oneChange= (e) => this.setState({ collateral_one: e.target.value});
  handlecollateral_one_valueChange= (e) => this.setState({ collateral_one_value: e.target.value});
  handlecollateral_twoChange= (e) => this.setState({ collateral_two: e.target.value});
  handlecollateral_two_valueChange= (e) => this.setState({ collateral_two_value: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <Result
            status="success"
            title="Successfully Submitted loan Application "
            subTitle="Application will be ready for asessment"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />

          </Card>
          </Col>
          </Row>
        );

      }else{

        if(this.state.loanapplications.length>=this.state.saccopackageobj.loanslimit){
          return (
            <div>
              <h4 style={{color:'red'}}>You have reached loans limit for this package.Please contact adminstrators for upgrade of package.Thanx</h4>
            </div>
          );

        }else{


          return (
            <div>
                <Row >
                      <Col xs="12" sm="6" lg="6">
                        <Card>
                        <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN APPLICATION FORM</h4>
                        <Form
                          onFinish={(event) => this.handleFormSubmit(event)} 
                        >
    
                        <FormItem label="Loan Type"
                            name="loantype"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Type',
                              },
                            ]}           
                          >
                              <Select 
                              placeholder="Loan Type" 
                              style={{  }} 
                              value={this.state.loan_type} 
                              onChange={this.handleloan_typeChange} 
                              showSearch
                              optionFilterProp="children"
                              onFocus={this.onFocus}
                              onBlur={this.onBlur}
                              onSearch={this.onSearch}
                              
                              >
                                {this.state.loantypes.map(
                                  (stf)=>(
                                    <Option value={stf.id}>{stf.loan_type_name}</Option>
                                  ))}
                              </Select>
                          </FormItem>
    
    
                          <FormItem label="Account"
                            name="account"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Account',
                              },
                            ]}           
                          >
                              <Select 
                              placeholder="Account" 
                              style={{  }} value={this.state.account} 
                              onChange={this.handleaccountChange} 
                              showSearch
                              optionFilterProp="children"
                              onFocus={this.onFocus}
                              onBlur={this.onBlur}
                              onSearch={this.onSearch}
                              >
                                {this.state.members.map(
                                  (stf)=>(
                                    <Option value={stf.id}>{stf.AccountNumber} | {stf.FullName} </Option>
                                  ))}
                              </Select>
                          </FormItem>
 
    
                          <FormItem label="Loan Purpose"
                            name="loan_purpose"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter purpose',
                              },
                            ]}           
                          >
                            <Input name="loan_purpose"  placeholder="Purpose" value={this.state.loan_purpose} onChange={this.handleloan_purposeChange} />
                          </FormItem>
    

                          <FormItem label="Applicant Location"
                            name="applicant_location"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter location',
                              },
                            ]}           
                          >
                            <Input name="applicant_location"  placeholder="Location" value={this.state.applicant_location} onChange={this.handleapplicant_locationChange} />
                          </FormItem>

                          <FormItem label="Loan Request Amount"
                            name="loan_amount"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter values',
                              },
                            ]}           
                          >
    
                          <InputNumber
                            style={{ width: 200 }} 
                            defaultValue={0.0}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\x\s?|(,*)/g, '')}
                            onChange={this.handleloan_amountChange}
                          />
    
                          </FormItem>


                          <FormItem label="Any other Collaterals? then click to add.">
                          <PlusCircleFilled 
                          style={{fontSize: '35px',margin:2,color:'#9B116F'}}
                          onClick={()=>{
                            this.setState({othercol_model_visible:true})
                          }}
                          />
                        </FormItem>


                        {
                            this.state.othercollateral_list.length>0?
                            <reactstrp.Table bordered>
                            <thead>
                              <tr>
                                <th>Collateral details</th>
                                <th>Value</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                            {this.state.othercollateral_list.map(
                              (item)=>(
                                <tr>
                                <td>{item.othercol_details}</td>
                                <td><CurrencyFormat value={item.othercol_value} displayType={'text'} thousandSeparator={true}  /></td>

                                <td>
                                <Popover content={<p>Click here to delete item</p>} title="Delete Item">
                                <Popconfirm title="Sure to delete?" onConfirm={() => {
                                  this.setState({ guarantorslist:  [...this.state.guarantorslist.filter(todo => todo.key
                                    !==item.key)]});
                                }}>
                                <CloseCircleFilled style={{color:'red'}}  />
                                </Popconfirm>       
                                </Popover>
                                </td>
                                </tr>
                              ))}
                          </tbody>
                          </reactstrp.Table>  
                          :
                          null
                        }


                          <h3>Saving period: {this.returnsavingsperiod()} days</h3>

                          <h3>Member, Guarantors' savings plus shares: <CurrencyFormat value={this.calculateTotal_g_m_savingsandshares()} displayType={'text'} thousandSeparator={true}  /> </h3>
                          <h3>Loan limit: <CurrencyFormat value={this.calculate_loanlimit()} displayType={'text'} thousandSeparator={true}  /></h3>

                          <FormItem>
                            <Button  type="primary" htmlType="submit">Create</Button>
                          </FormItem>
                        </Form>
                        
    
                        </Card>
                        </Col>
    
    
                        <Col xs="12" sm="6" lg="6">
                        <Card>
                        <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Loan Type Details</h4>
                        <h6>Loan Type Name: {this.state.loantypeobj.loan_type_name}</h6>
                        <h6>Interest Rate: {this.state.loantypeobj.interest_rate}</h6>
                        <h6>Maximum Loan Amount: {this.state.loantypeobj.maximum_loan_amount}</h6>
                        <h6>Maximum Period: {this.state.loantypeobj.maximum_period}</h6>
                        <br></br>
                        <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Account Details</h4>
                        <h6>Account Name: {this.state.accountobj.FullName}</h6>
                        <h6>Account Number: {this.state.accountobj.AccountNumber}</h6>
                        <h6>Date of joining: {this.state.accountobj.DateOfJoining}</h6>
                        <h6>Savings Balance: <CurrencyFormat value={this.state.accountobj.UserBalance} displayType={'text'} thousandSeparator={true}  /></h6>
                        <h6>Loan Balance: <CurrencyFormat value={this.state.accountobj.loan_balance} displayType={'text'} thousandSeparator={true}  /></h6>

                        <h6>Shares number: {this.state.accountobj.sharesnumber_balance}</h6>
                        <h6>Shares amount:<CurrencyFormat value={this.state.accountobj.sharesamount_balance} displayType={'text'} thousandSeparator={true}  /> </h6>
                        <h6>Savings loan security balance:<CurrencyFormat value={this.state.accountobj.savingsloan_security_balance} displayType={'text'} thousandSeparator={true}  /> </h6>
                        <h6>Shares loan security balance:<CurrencyFormat value={this.state.accountobj.shares_security_balance} displayType={'text'} thousandSeparator={true}  /> </h6>

                        <div style={{display:'flex',flexDirection:'row',margin:3}}>

                        <h3 style={{color:'green'}}>Guarantors</h3>
                          <PlusCircleFilled 
                          style={{fontSize: '35px',margin:2,color:'#9B116F'}}
                          onClick={()=>{
                            this.setState({guarantorformvisible:true})
                          }}
                          />
                          </div>
                          
                          {
                            this.state.guarantorslist.length>0?
                            <reactstrp.Table bordered>
                            <thead>
                              <tr>
                                <th>Account Name</th>
                                <th>Account Number</th>
                                <th>Savings</th>
                                <th>Shares</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                            {this.state.guarantorslist.map(
                              (item)=>(
                                <tr>
                                <td>{item.account_name}</td>
                                <td>{item.account_no}</td>
                                <td><CurrencyFormat value={item.guar_savingsAmount} displayType={'text'} thousandSeparator={true}  /></td>
                                <td><CurrencyFormat value={item.guar_sharesAmount} displayType={'text'} thousandSeparator={true}  /></td>

                                <td>
                                <Popover content={<p>Click here to delete item</p>} title="Delete Item">
                                <Popconfirm title="Sure to delete?" onConfirm={() => {
                                  this.setState({ guarantorslist:  [...this.state.guarantorslist.filter(todo => todo.key
                                    !==item.key)]});
                                }}>
                                <CloseCircleFilled style={{color:'red'}}  />
                                </Popconfirm>       
                                </Popover>
                                </td>
                                </tr>
                              ))}
                          </tbody>
                          </reactstrp.Table>  
                          :
                          null
                        }
                       
                        </Card>
                        </Col>
                </Row>

          <Modal              
              visible={this.state.guarantorformvisible}
              title={"Guarantor Form"}
              onCancel={()=>{
                this.setState({guarantorformvisible:false})
              }}
              //style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{
                this.setState({guarantorformvisible:false})

                }}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{
                         //check if guaraantor exists on the list
                         var guarantor_savingstotal=Number(this.state.selectedguarantorobj.UserBalance)-Number(this.state.selectedguarantorobj.savingsloan_security_balance)
                         var guarantor_sharestotal=Number(this.state.selectedguarantorobj.sharesamount_balance)-Number(this.state.selectedguarantorobj.shares_security_balance)

                        if(this.state.account===""){
                          message.error("Please first select member account")
                        }else if(this.state.selectedguarantor===""){
                          message.error("Please select guarantor account")
                        }else if (Number(this.state.guar_savingsAmount)>guarantor_savingstotal){
                          message.error("Guarantor savings amount cant be bigger than savings balance")

                        }else if (Number(this.state.guar_sharesAmount)>guarantor_sharestotal){
                          message.error("Guarantor shares amount cant be bigger than shares balance")
                        }
                        
                        else{

                        const gitem={
                          key:uuid(),
                          selectedguarantor:this.state.selectedguarantor,
                          guarantee_value:Number(this.state.guar_savingsAmount)+Number(this.state.guar_sharesAmount),
                          account_name:this.state.selectedguarantorobj.FullName,
                          account_no:this.state.selectedguarantorobj.AccountNumber,
                          guar_savingsAmount:this.state.guar_savingsAmount,
                          guar_sharesAmount:this.state.guar_sharesAmount,
                        }

                        this.setState({guarantorslist: [...this.state.guarantorslist, gitem]});
                        this.setState({guarantorformvisible:false})
                          
                        }

                    }}>
                      {
                        "Add Guarantor"
                      }
                </Button>
                ]}
                >
                  <FormItem label="Account"
                      name="account"
                      rules={[
                        {
                          required: true,
                          message: 'Please select account',
                        },
                      ]}               
                  >
                      <Select 
                      placeholder="Account" 
                      style={{  }}
                      value={this.state.selectedguarantor} 
                      onChange={(val)=>{
                      this.setState({selectedguarantor:val})

                      axios.get(`${serverconfig.backendserverurl}/api/members/${val}`)
                      .then(res => {  
                          this.setState({selectedguarantorobj: res.data})
                      })
                    
                      }} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      >
                        {this.state.members.map(
                          (stf)=>{
                            if (Number(this.state.account)===Number(stf.id)){
                              return null
                            }else{
                              return (
                                <Option value={stf.id}>{stf.AccountNumber} | {stf.FullName} </Option>
                              )
                            }
                          }
                          )}
                      </Select>
                     </FormItem>
                     <br></br>
                      <h4>Savings Balance:<CurrencyFormat value={this.state.selectedguarantorobj.UserBalance} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h6>Loan Balance: <CurrencyFormat value={this.state.selectedguarantorobj.loan_balance} displayType={'text'} thousandSeparator={true}  /></h6>
                      <h4>Shares Amount Balance:<CurrencyFormat value={this.state.selectedguarantorobj.sharesamount_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>Savings loan security balance:<CurrencyFormat value={this.state.selectedguarantorobj.savingsloan_security_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>Shares loan security balance:<CurrencyFormat value={this.state.selectedguarantorobj.shares_security_balance} displayType={'text'} thousandSeparator={true}  /> </h4>

                      <br></br>

                      <div style={{display:'flex',flexDirection:'row'}}>
                        <FormItem label="Savings">
                        <Switch
                          checked={this.state.g_gua_savings}
                          onChange={val=>{this.setState({g_gua_savings:val})}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                        </FormItem>
                        
                        <FormItem label="Shares">
                        <Switch
                          checked={this.state.g_gua_shares}
                          onChange={val=>{this.setState({g_gua_shares:val})}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                        </FormItem>
                        </div>

                        {
                          this.state.g_gua_savings===true?
                          <FormItem label="Guarantor savings amount" >
                          <Input name="guar_savingsAmount" type='number'  placeholder="0.00" value={this.state.guar_savingsAmount} onChange={(val)=>{this.setState({guar_savingsAmount:val.target.value})}} />
                          </FormItem>     
                          :
                          null
                        }

                        {
                          this.state.g_gua_shares===true?
                          <FormItem label="Guarantor Shares amount" >
                          <Input name="guar_sharesAmount" type='number'  placeholder="0.00" value={this.state.guar_sharesAmount} onChange={(val)=>{this.setState({guar_sharesAmount:val.target.value})}} />
                          </FormItem>     
                          :
                          null
                        }    

                     <h3>Guarantor available total  <CurrencyFormat value={this.calculatetotalof_guarantorsavingsavailable()} displayType={'text'} thousandSeparator={true}  /> </h3>
               </Modal>



              <Modal              
              visible={this.state.othercol_model_visible}
              title={"Other Collateral Details Form"}
              onCancel={()=>{this.setState({othercol_model_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({othercol_model_visible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{

                        var intobj={
                          'key':uuid(),
                          'othercol_details':this.state.othercol_details,
                          'othercol_value':this.state.othercol_value,
                        }

                        this.setState({othercollateral_list: [...this.state.othercollateral_list, intobj]});

                        this.setState({othercol_model_visible:false})

                        this.setState({othercol_details:''})
                        this.setState({othercol_value:0})

                    }}>
                  Add Collateral
                </Button>
                ]}
                >
                  
                <FormItem label="Colateral Details" >
                    <Input name="othercol_details"  placeholder="colateral details" value={this.state.othercol_details} onChange={(val)=>{this.setState({othercol_details:val.target.value})}} />
                </FormItem>                  

                <FormItem label="Collateral Value" >
                    <Input name="othercol_value"  placeholder="Collateral Value" value={this.state.othercol_value} onChange={(val)=>{this.setState({othercol_value:val.target.value})}} type='number' />
                </FormItem> 
               </Modal> 

            </div>
          );

        }

      }
     
    }
    
  }

}


export default LoanApplicationForm;



