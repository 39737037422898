import React from 'react';
import { Form, Input, Button,Spin,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

var token= ''
var sacco= ''


class LoanProcessingFeesForm extends React.Component {

  state = {
    range_name:'' ,  
    range_start_value:0,
    range_stop_value:0,
    charge_type:'',
    charge_value:0,
    datarequested:false,
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('range_name', this.state.range_name);
    form_data.append('range_start_value', this.state.range_start_value);
    form_data.append('range_stop_value', this.state.range_stop_value);
    form_data.append('IDvaliditydays', this.state.IDvaliditydays);
    form_data.append('charge_type', this.state.charge_type);
    form_data.append('charge_value', this.state.charge_value);
    form_data.append('sacco', sacco);

      axios.post(serverconfig.backendserverurl+'/api/loan_processing_fees/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }


  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Range Name"
        name="range_name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="range_name"  placeholder="Put a name here." value={this.state.range_name} onChange={(val)=>{this.setState({range_name:val.target.value})}} />
      </FormItem>

     

      <FormItem label="Range start value"
        name="range_start_value"
        rules={[
          {
            required: true,
            message: 'Please input value',
          },
        ]}    
      >
        <Input name="range_start_value" type='number'  placeholder="Range start value" value={this.state.range_start_value} onChange={(val)=>{this.setState({range_start_value:val.target.value})}} />
      </FormItem>

      <FormItem label="Range end value"
        name="range_stop_value"
        rules={[
          {
            required: true,
            message: 'Please input value',
          },
        ]}    
      >
        <Input name="range_stop_value" type='number'  placeholder="Range end value" value={this.state.range_stop_value} onChange={(val)=>{this.setState({range_stop_value:val.target.value})}} />
      </FormItem>

      <FormItem 
        label="Charge type"
        name="charge_type"
        rules={[
          {
            required: true,
            message: 'Please select charge type',
          },
        ]}
        >
            <Select 
            value={this.state.charge_type} 
            onChange={(val)=>{this.setState({charge_type:val})}}
            placeholder="Charge Type" >
                  <Option value='block'>Block</Option>
                  <Option value='percentage'>Percentage</Option>
            </Select>
        </FormItem>


        <FormItem label="Charge value"
        name="charge_value"
        rules={[
          {
            required: true,
            message: 'Please input value',
          },
        ]}    
      >
        <Input name="charge_value" type='number'  placeholder="Charge value" value={this.state.charge_value} onChange={(val)=>{this.setState({charge_value:val.target.value})}} />
      </FormItem>


      <FormItem>
        <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
      </FormItem>

      </Form>
        </div>
      );

    }
    
  }

}


export default LoanProcessingFeesForm;



