import React from 'react';
import MemberForm from '../components/MemberForm'
import ActivateMemberForm from '../components/ActivateMemberForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Typography,Space,Divider,Popover,Popconfirm,message,Descriptions,Modal,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,PrinterOutlined,FundViewOutlined,EditOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import ExpensePaymentForm from '../components/ExpensePaymentForm'
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Title } = Typography;

function callback(key) {
  console.log(key);
}

var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })


  }

  render() {
    return (
      <div style={{padding:20}}>
<Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.sacco_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
                <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
              
                </Col>

         </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>FIXED DEPOSITS REPORT</h3>

           <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date of fixing</th>
                          <th>Account Number</th>
                          <th>Account Name</th>
                          <th>Period</th>
                          <th>Interest Rate</th>
                          <th>Interest rate Type</th>
                          <th>Fixed Amount</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.props.fixeddeposits.map(
                        (item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.accountno}</td>
                          <td>{item.accountname}</td>
                          <td>{item.period}</td>
                          <td>{item.interestrate}</td>
                          <td>{item.interesttype}</td>
                          <td>{<CurrencyFormat value={item.fixedamount} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalfixedamount} displayType={'text'} thousandSeparator={true}/>}</td>
                       
                        </tr>

                    </tbody>
                </reactstrp.Table>    

      </div>
    );
  }
}


class FixedDepositReport extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    fixeddeposits: [],
    viewpaymentmodal:false,
    expensedetails:{},
    companyprofile:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    totalshares:0,
    totalwelfare:0,
    totalemergency:0,
    totaldream:0,
    totallegacy:0,
    totalchildren:0,
    totaluniversity:0,

  };

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/fixeddeposits/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          fixeddeposits:res.data
        })
        console.log(res.data)

        this.setState({datarequested:false})

    })

}

    //calculate total savings
    calculatetotalfixedamount=()=>{
      var Total=0
      this.state.fixeddeposits.map(
        (item)=>{
          Total+=Number(item.fixedamount)
        })
  
      return Total;

    }



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});


  render() {
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              totalfixedamount={this.calculatetotalfixedamount()} 
              fixeddeposits={this.state.fixeddeposits}
              ref={el => (this.componentRef = el)} /> 
             </div>


            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}> FIXED DEPOSITS REPORT</h4>} key="1">
           
               <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date of fixing</th>
                          <th>Account Number</th>
                          <th>Account Name</th>
                          <th>Period</th>
                          <th>Interest Rate</th>
                          <th>Interest rate Type</th>
                          <th>Fixed Amount</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.fixeddeposits.map(
                        (item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.accountno}</td>
                          <td>{item.accountname}</td>
                          <td>{item.period}</td>
                          <td>{item.interestrate}</td>
                          <td>{item.interesttype}</td>
                          <td>{<CurrencyFormat value={item.fixedamount} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculatetotalfixedamount()} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table>              
           
                <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
              </div>

            </Panel>
            </Collapse>

        </div>
    )

    }

  }
}

export default FixedDepositReport; 
