import React,{ useRef } from 'react';
import StaffForm from './StaffForm'
import axios from 'axios'
import { Tooltip,Popover,Result,Tabs,Table,Popconfirm,Input,Switch, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';
import SavingsCreateInterest_Excel from './SavingsCreateInterestView_Excel'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { RangePicker } = DatePicker;

const toWords = new ToWords();
const { TabPane } = Tabs;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''
var bizuserid= ''

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

 

  }

  render() {
    return (
      <div style={{padding:20}}>
            
          <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
          <h3 style={{color:'black'}}>{this.state.companyprofile.sacco_name}-{this.state.companyprofile.branchname}</h3>
          <h6 style={{color:'black'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
          {
          this.state.companyprofile.RegisteredPhoneNumber2==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber2
          }
          {
          this.state.companyprofile.RegisteredPhoneNumber3==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber3
          }
          </h6>               
          <h5 style={{color:'black'}}>Email: {this.state.companyprofile.company_email}</h5>
          <h5 style={{color:'black'}}>Wesite: {this.state.companyprofile.website}</h5>
          <h5 style={{color:'black'}}>{this.state.companyprofile.box_number}</h5>
           <h4 style={{color:'black'}}>SLIP NO: {this.props.voucher_number}</h4>
           <h4 style={{color:'black'}}>DATE: {this.props.date}</h4>
           <h5 style={{color:'black'}}>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
           <h3 style={{strong:true,color:'black',fontWeight:'bolder'}}>DEPOSIT RECEIPT</h3>

           <table cellpadding="2" cellSpacing="2" border="1">
            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.AccountNumber}</td>
            </tr>

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NAME</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.FullName}</td>
            </tr>  

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>DEPOSITED AMOUNT</td>
              <td style={{fontWeight:'bold'}}><CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={true} /></td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>In Words</td>
              <td style={{fontWeight:'bold'}}>{ toWords.convert(this.props.totalamount)}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>PAID IN BY</td>
              <td style={{fontWeight:'bold'}}>{this.props.paidinby}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>SLIP NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.voucher_number}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>CONTACT</td>
              <td style={{fontWeight:'bold'}}>{this.props.transactorcontact}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>TELLER</td>
              <td style={{fontWeight:'bold'}}>{username}</td>
            </tr> 

           </table>


        
    </div>
    );
  }
}


class SavingsCreateInterest extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    voucher_number:'',
    paidinby:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    transaction_details:'',
    interestrate:0,
    supersave_profile:{},
    receivers_list:[],
    dateone:'',
    datetwo:'',
    members: [],
    past_days:0,
    maximum_interest:0,
    minimum_interets:0,
    send_email:false,
    send_sms:false,
    show_txns:false,
    memberTransactions:[],
    
    deduct_tax:false,
    tax_percentage:0,
    companyaccounts: [],
    creditaccount:'',
    debitaccount:'',
    taxcredit_account:'',
    distributionmode:'deposittoaccounts',

  }



  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })


    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
          
        })
        var firstobj=res.data[0]

        this.setState({maximum_interest:firstobj.interest_limit})
        this.setState({minimum_interets:firstobj.mandatory_interest})

        this.setState({datarequested:false})
    })


    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

  }

  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleselectedaccountChange= (e) =>{
    this.setState({accountchange:true})
    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
          this.setState({accountchange:false})
      })

      //get teebagoolo and supersave profiles
      axios.get(`${serverconfig.backendserverurl}/api/supersaveprofile/?member_account=${e}`)
      .then(res => {  
          this.setState({supersave_profile: res.data[0]})
      })
  }

  handlepaidinbyChange= (e) => this.setState({ paidinby: e.target.value});
  handletransactorcontactChange= (e) => this.setState({ transactorcontact: e});


 //check empty objecty
 isEmptyObject=(obj)=>{
  for ( var name in obj ) {
    return false;
  }
  return true;
}

calCulateDailyInterest=()=>{
  var interst=0
  interst=((this.state.interestrate/100)/365);
  return interst;
}


  //submit the deposit now
  handleFormSubmit=() =>{

      if (this.state.receivers_list.length===0){
        message.error("receivers list cant be empty")
      }else if(Number(this.state.interestrate)<Number(this.state.minimum_interets)){
        message.error("interest cant be less than: "+String(this.state.minimum_interets))

      }else if(Number(this.state.interestrate)>Number(this.state.maximum_interest)){
        message.error("interest cant be greater than: "+String(this.state.maximum_interest))}
      
      else if (this.state.tax_percentage>0 && this.state.taxcredit_account===''){
        message.error("please select tax credit account")

      }else if(this.state.deduct_tax===true && this.state.tax_percentage<=0)
      message.error("Tax percentage cant be less or equal to zero")

      else{

        this.setState({datarequested:true})
        let form_data = new FormData();
        form_data.append('date', this.state.date);
        form_data.append('interestrate', this.state.interestrate);
        form_data.append('sacco', sacco);
        form_data.append('account', this.state.selectedaccountobject.id);
        form_data.append('username', username);
        form_data.append('userid', bizuserid);
        form_data.append('transaction_details', this.state.transaction_details);
        form_data.append('voucher_number', this.state.voucher_number);
        form_data.append('receivers_list',JSON.stringify(this.state.receivers_list) );
  
        form_data.append('send_email', this.state.send_email);
        form_data.append('send_sms', this.state.send_sms);

        form_data.append('dateone', this.state.dateone);
        form_data.append('datetwo', this.state.datetwo);

        form_data.append('deduct_tax', this.state.deduct_tax);
        form_data.append('tax_percentage', this.state.tax_percentage);
        form_data.append('creditaccount', this.state.creditaccount);
        form_data.append('debitaccount', this.state.debitaccount);
        form_data.append('taxcredit_account', this.state.taxcredit_account);
        form_data.append('distributionmode', this.state.distributionmode);

        axios.post(serverconfig.backendserverurl+'/customqueries/createOrdinarysavingsInterest', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
        .then(res => {
          this.setState({datarequested:false})
          message.info(res.data.message)
          if (res.data.message==="success"){
            this.setState({datasubmittedsuccessfully:true})
          }
        })
        .catch(error => console.log(error))

      }

  }




 //calculate interest here
  calculate_interest=(rate)=>{
    //var rate=this.state.interestrate
    console.log("rate: "+this.state.interestrate)

    var dateMomentObjectone = moment(this.state.dateone, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjectone = dateMomentObjectone.toDate();

    var dateMomentObjecttwo = moment(this.state.datetwo, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjecttwo =dateMomentObjecttwo.toDate()

    var date1 = dateObjectone;
    var date2 = dateObjecttwo;

    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    console.log(Difference_In_Days)
    this.setState({past_days:Difference_In_Days})

  }


  //calculatetotals
calculate_listToyal=()=>{
  var total=0;

  this.state.receivers_list.map((item)=>{
    total+=Number(item.accountbalance)
  })

  return total;
}

calculate_listpay=()=>{
  var total=0;
  this.state.receivers_list.map((item)=>{
    total+=Number(item.interest_pay)
  })

  return total;
}


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            account={this.state.selectedaccountobject} 
            totalamount={this.state.interestrate} 
            voucher_number={this.state.voucher_number} 
            paidinby={this.state.paidinby} 
            transactorcontact={this.state.transactorcontact}
            date={this.state.date}
            ref={el => (this.componentRef = el)} /> 
            </div>

            <Row >
                <Col xs="12" sm="6" lg="6">
                  <Card>
                  <Result
                  status="success"
                  title="Savings interest awarding running"
                  extra={[
                    <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                  ]}
                  />
                </Card>
                </Col>
            </Row>
          </div>

        )

      }else{
        return(
          <div>
          <Tabs defaultActiveKey="1" onChange={()=>{}}>
                <TabPane tab="Interest by system transactions" key="001" >
                <div>
  
  <Row >
      <Col xs="12" sm="6" lg="5">
        <Card>
        
        <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}>SAVINGS INTEREST AWARD FORM</h3>
      <Form
        >

    <FormItem label="Interest award period (From to)">
          <RangePicker defaultValue={moment()} onChange={(date, dateString) =>{
              this.setState({ dateone: dateString[0]});
              this.setState({ datetwo: dateString[1]});
            }} format={dateFormat} />
      </FormItem>
    

     <FormItem label={<h6 style={{fontWeight:'bold'}}>Interest Rate (Annual) </h6>}>
          <Input name="interestrate"   placeholder="0" value={this.state.interestrate}
           onChange={(e) =>{
              this.setState({ interestrate: e.target.value})
              this.calculate_interest(e.target.value)

            }
          } />
      </FormItem>

      <FormItem>
        <Button  type="primary" htmlType='button'
        onClick={()=>{
        if(Number(this.state.interestrate)<=0){
          message.error("Please enter interest rate")
        }else{
  
          this.setState({datarequested:true})
          let form_data = new FormData();
          form_data.append('sacco', sacco);
          form_data.append('interestrate', this.state.interestrate);
          form_data.append('dateone', this.state.dateone);
          form_data.append('datetwo', this.state.datetwo);    
          axios.post(serverconfig.backendserverurl+'/customqueries/calculate_savingsinterest', form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
            })
          .then(res => {
            this.setState({datarequested:false})
            this.setState({receivers_list:JSON.parse(res.data.receivers_list)})

          })
          .catch(error => console.log(error))
  
        }

        }}
        
        >Calculate interest</Button>
        </FormItem>
        &nbsp;&nbsp;


      <FormItem label={<h4 style={{fontWeight:'bold'}}>Transaction details.</h4>} >
    
      <TextArea
          placeholder="Transaction Details"
          autoSize={{ minRows: 2, maxRows: 8 }}
          value={this.state.transaction_details}
          onChange={(val)=>{this.setState({transaction_details:val.target.value})}}
        />
      </FormItem>

      <FormItem label="Send email?" >
          <Switch
              checked={this.state.send_email}
              onChange={val=>{this.setState({send_email:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
      </FormItem>

      <FormItem label="Send sms?" >
          <Switch
              checked={this.state.send_sms}
              onChange={val=>{this.setState({send_sms:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
      </FormItem>

      <FormItem label="Deduct tax? From each members interest amount" >
          <Switch
              checked={this.state.deduct_tax}
              onChange={val=>{this.setState({deduct_tax:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
      </FormItem>
      
      {
        this.state.deduct_tax?
        <FormItem label={<h6 style={{fontWeight:'bold'}}>Tax percentage </h6>}>
            <Input name="tax_percentage" 
              placeholder="0"
               value={this.state.tax_percentage}
              onChange={(e) =>{
                this.setState({ tax_percentage: e.target.value})  
              }
            } />
        </FormItem>
        :
        null
      }


<FormItem label={<h4 style={{fontWeight:'bold'}}>Debit A/C.</h4>}>
    <Select 
    placeholder="Account" 
    style={{  }} 
    value={this.state.debitaccount}
    onChange={(val)=>{this.setState({debitaccount:val})}} 
    showSearch
    optionFilterProp="children"
    onFocus={this.onFocus}
    onBlur={this.onBlur}
    onSearch={this.onSearch}                    
    >
      {this.state.companyaccounts.map(
        (accnt)=>(
          <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
        ))}
    </Select>
</FormItem>

<FormItem label={<h4 style={{fontWeight:'bold'}}>Credit A/C .</h4>}>
    <Select 
    placeholder="Account" 
    style={{  }} 
    value={this.state.creditaccount}
    onChange={(val)=>{this.setState({creditaccount:val})}} 
    showSearch
    optionFilterProp="children"
    onFocus={this.onFocus}
    onBlur={this.onBlur}
    onSearch={this.onSearch}                    
    >
      {this.state.companyaccounts.map(
        (accnt)=>(
          <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
        ))}
    </Select>
</FormItem>

{
  this.state.tax_percentage>0?
  <FormItem label={<h4 style={{fontWeight:'bold'}}>Tax credit A/C .</h4>}>
  <Select 
  placeholder="Account" 
  style={{  }} 
  value={this.state.taxcredit_account}
  onChange={(val)=>{this.setState({taxcredit_account:val})}} 
  showSearch
  optionFilterProp="children"
  onFocus={this.onFocus}
  onBlur={this.onBlur}
  onSearch={this.onSearch}                    
  >
    {this.state.companyaccounts.map(
      (accnt)=>(
        <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
      ))}
  </Select>
</FormItem>

  :
  null
}

<FormItem 
        label="Dividends Distribution Mode"
       
        >
            <Select 
            value={this.state.distributionmode} 
            onChange={(val)=>{this.setState({distributionmode:val})}}
            placeholder="Dividend Distribution Mode" >
                  <Option value='deposittoaccounts'>Deposit to Savings</Option>
{ /*                 <Option value='deposittoshares'>Deposit to Shares</Option>
*/}            </Select>
        </FormItem>


      <br></br>
     
   
        <div style={{display:'flex',flexDirection:'row',margin:6}}>

      


        <FormItem>
          <Button  type="primary" htmlType="button"

          onClick={()=>{

            this.handleFormSubmit()


          }}
          
          >Award interest now</Button>
        </FormItem>

        </div>
        
     
      </Form>

      </Card>
      </Col>

      <Col xs="12" sm="8" lg="6">
        <Card>
        <h3 style={{fontWeight:'bold',color:'#154360'}}>List of receivers and totals</h3>
        <h4 style={{fontWeight:'bold',color:'#154360'}}>past days: {this.state.past_days}</h4>
        <h4 style={{fontWeight:'bold'}}>You are about to  award savings interest at rate of {this.state.interestrate} per annum and {this.calCulateDailyInterest()} perday  </h4>

        <div style={{'overflow-x':'auto'}}>

        {
            this.state.receivers_list.length>0?
              <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th>Account name</th>
                  <th>Account number</th>
                  <th>Account balance</th>
                  <th>Interest pay</th>
                  <th>Txns</th>

                </tr>
              </thead>
              <tbody>
              {this.state.receivers_list.map(
                (item)=>(
                  <tr key={item.key}>
                  <td>{item.FullName}</td>
                  <td>{item.AccountNumber}</td>
                  <td>{<CurrencyFormat value={item.accountbalance} displayType={'text'} thousandSeparator={true}/>}</td>
                  <td>{<CurrencyFormat value={item.interest_pay} displayType={'text'} thousandSeparator={true}/>}</td>
                  <td><Button type='primary' onClick={()=>{
                    this.setState({memberTransactions:item.report})
                    this.setState({show_txns:true})
                  }}>View</Button></td>
                  </tr>
                ))}
              
                <tr>
                <td style={{fontWeight:'bolder'}}>Total</td>
                <td></td>
                <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculate_listToyal().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculate_listpay().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                <td></td>
                </tr>

            </tbody>
            </reactstrp.Table> 
            :
            null
          }

        </div>
         
        </Card>
        
      </Col>
   </Row>


  <Modal    
  visible={this.state.show_txns}
  title="Periodic transactions."
  onCancel={()=>{this.setState({show_txns:false})}}
  footer={[
    <Button key="back" onClick={()=>{this.setState({show_txns:false})}}>
      Cancel
    </Button>
    ]}
    >
      {
        this.state.memberTransactions.length>0?
        <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>No of days</th>
                  <th>Amount to receive interest</th>
                  <th>Interest amount</th>
                </tr>
              </thead>
              <tbody>
              {this.state.memberTransactions.map(
                (item)=>(
                  <tr key={item.key}>
                  <td>{item.date}</td>
                  <td>{item.no_ofdays}</td>
                  <td>{<CurrencyFormat value={item.amount_toreceiveinterest} displayType={'text'} thousandSeparator={true}/>}</td>
                  <td>{<CurrencyFormat value={item.interest_amount} displayType={'text'} thousandSeparator={true}/>}</td>
               
                  </tr>
                ))}
              
            </tbody>
            </reactstrp.Table> 

        :
        null
      }

</Modal>

</div>


                </TabPane>
                <TabPane tab="Interest award by excel" key="002" >
                  <SavingsCreateInterest_Excel />


                </TabPane>
          </Tabs>



       </div>              
                      
       )
      }
    
    }
   
  }
}

export default SavingsCreateInterest; 
