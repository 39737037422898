import React from 'react';
import { Form, Input, Button,Select,Spin,Result,message,Card,Switch,InputNumber } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;


var sacco= ''
var username=''
var token= ''

class LoanAppovalForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    approved_amount:0,
    loan_pass:false,
    approval_report:'',
    loanapplication:{},
    datasubmittedsuccessfully:false,
    datarequested:true,

    reasonforrejection:'',
    sendapprovalsms:false,
    loantypeobject:{},
    approved_period:0

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    const loanapplicationID=this.props.match.params.loanapplicationID;

    axios.get(`${serverconfig.backendserverurl}/api/loanapplications/${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapplication: res.data})
        this.setState({approved_amount:res.data.loan_amount})

        axios.get(`${serverconfig.backendserverurl}/api/loantypes/${res.data.loan_type}`)
        .then(res => {  
            this.setState({loantypeobject: res.data})
        })

        this.setState({datarequested:false})

    })

  }

  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    const loanapplicationID=this.props.match.params.loanapplicationID;

    if(Number(this.state.approved_amount)>Number(this.state.loanapplication.loan_amount)){
      message.error("Approved amount cant be greater than amount applied for")
    }else if (Number(this.state.approved_amount)>Number(this.state.loanapplication.application_loan_limit)){
      message.error("Approved amount cant be greater than the application loan limit")
    }else if (Number(this.state.approved_amount)==0 && this.state.loan_pass===true ){
      message.error("Approved amount cant be zero when loan is approved")
    }else if (this.isloan_approved()===false && this.state.loan_pass===true){
      message.error("Loan cant be approved finally.More approvals needed")
    }else if (Number(this.state.approved_period)<=0){
      message.error("Period cant be less or equal to zero")
    }
    else if (Number(this.state.approved_period)>Number(this.state.loantypeobject.maximum_period)){
      message.error("Period cant be greater than the maximum set period")
    }
    
    else{

      this.setState({datarequested:true})
      let form_data = new FormData();
      form_data.append('date', this.state.date);
      form_data.append('loan_id', loanapplicationID);
      form_data.append('approved_amount', this.state.approved_amount);
      form_data.append('amount_appliedfor', this.state.loanapplication.loan_amount);

      form_data.append('approval_report', this.state.approval_report);
      form_data.append('loan_passed', this.state.loan_pass);
      form_data.append('user',username);
  
      form_data.append('reasonforrejection', this.state.reasonforrejection);
      form_data.append('approved_period', this.state.approved_period);
      form_data.append('sacco', sacco);
      
      axios.post(serverconfig.backendserverurl+'/api/loanapprovals/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>  {
      var obj=res.data
      console.log(Object.keys(obj).length)
      if(Object.keys(obj).length>0){
  
        let form_data = new FormData();
        form_data.append('looanid', loanapplicationID);
        form_data.append('sendapprovalsms', this.state.sendapprovalsms);
        form_data.append('reasonforrejection', this.state.reasonforrejection);
      
        form_data.append('approved_period', this.state.approved_period);
        form_data.append('approved_amount', this.state.approved_amount);
        form_data.append('date', this.state.date);

        if(this.state.loan_pass===true){
          form_data.append('loanpassed', 'true');
        }else{
          form_data.append('loanpassed', 'false');
        }
  
        axios.post(serverconfig.backendserverurl+'/customqueries/updateloanappovalstatus', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
        .then(res => {
            message.info(res.data.message)
            this.setState({datarequested:false})
            this.setState({datasubmittedsuccessfully:true})
        })
        .catch(error => console.log(error))
  
      }else{
        message.info("Some thing went wrong")
      }

    }       
  
    )
    .catch(error => console.log(error))
    }

  }

 
  
  handleapproved_amountChange= (e) => this.setState({ approved_amount: e.target.value});
  handleloan_passChange= (e) => this.setState({ loan_pass: e});
  handleapproval_reportChange= (e) => this.setState({ approval_report: e.target.value});

  //check approvals
  isloan_approved=()=>{
    var current_approvals=0

    if (this.state.loanapplication.loanapprovals!=undefined){
      current_approvals=JSON.parse(this.state.loanapplication.loanapprovals).length
    }
  
    if (current_approvals<Number(this.state.loantypeobject.no_of_approvers)){
      return false
    }else{
      return true
    }

  }



  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <Result
            status="success"
            title="Successfully Done loan approval "
            subTitle="If the loan is passed it will be forwarded for issuance or disburshment"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />

            </Card>
            </Col>
            </Row>
        );

      }else{
        return (
          <div>
              <Row >
                    <Col xs="12" sm="6" lg="6">
                      <Card>
                      <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Loan final approval form </h4>
                     
                     {
                       this.state.loanapplication.approval_yet?
                       null:
                       <Form
                       onFinish={(event) => this.handleFormSubmit(event)} 
                     >

                    <FormItem label="Approved Amount">
                         <Input name="approved_amount" type='number'  placeholder="Approval Amount." value={this.state.approved_amount} onChange={this.handleapproved_amountChange} />
                       </FormItem>

                       <FormItem label="Approved period">
                         <Input name="approved_period" type='number'  placeholder="Approved period." value={this.state.approved_period} onChange={(val)=>{this.setState({approved_period:val.target.value})}} />
                       </FormItem>
 
                     <FormItem label="Report Details"
                             name="approval_report"
                             rules={[
                               {
                                 required: true,
                                 message: 'Please enter details',
                               },
                             ]}            
                           >
                             <TextArea
                               value={this.state.approval_report}
                               onChange={this.handleapproval_reportChange}
                               placeholder="Report Details"
                               autoSize={{ minRows: 2, maxRows: 6 }}
                             />
                         
                       </FormItem>

                       <FormItem label="Pass Loan?" >
                           <Switch
                               checked={this.state.loan_pass}
                               onChange={val=>{this.handleloan_passChange(val)}}
                               checkedChildren="True"
                               unCheckedChildren="False"
                               />
                       </FormItem>

                        {
                          this.state.loan_pass?
                          <FormItem label="Send approval sms?" >
                          <Switch
                              checked={this.state.sendapprovalsms}
                              onChange={val=>{this.setState({sendapprovalsms:val})}}
                              checkedChildren="True"
                              unCheckedChildren="False"
                              />
                          </FormItem>
                          :
                          null
                        }

                       <FormItem>
                         <Button  type="primary" htmlType="submit">Submit</Button>
                       </FormItem>
                     </Form>
                     }
                     
  
                      </Card>
                      </Col>
  
                      <Col xs="12" sm="6" lg="6">
                      <Card>
                      
                      <h4 style={{fontStyle:'oblique',fontWeight:'bolder',color:'green'}}>Application summary</h4>
                      <h6 style={{fontWeight:'bold'}}>Amount applied for: <CurrencyFormat value={this.state.loanapplication.loan_amount} displayType={'text'} thousandSeparator={true}/></h6>
                      <h6 style={{fontWeight:'bold'}}>Application loan limit: <CurrencyFormat value={this.state.loanapplication.application_loan_limit} displayType={'text'} thousandSeparator={true}/></h6>
                      <h6 style={{fontWeight:'bold'}}>Current number of approvals: {this.state.loanapplication.loanapprovals!=undefined?    JSON.parse(this.state.loanapplication.loanapprovals).length:null}</h6>
                      <h6 style={{fontWeight:'bold'}}>Minimum total approvals: {this.state.loantypeobject.no_of_approvers}</h6>

                    </Card>
                    </Col>
              </Row>
  
          </div>
        );

      }

     
    }


    
  }

}


export default LoanAppovalForm;



