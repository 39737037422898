import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined,DownloadOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


var token= ''
var sacco= ''
var bizuserid= ''


class ReportToPrint extends React.Component {

    state = {
      companyprofile:{}
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })
  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h5>{this.state.companyprofile.companydealership}</h5>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>

                  </Col>

           </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>INCOME STATEMENT FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

             <h4>INCOMES </h4>
            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Income Category</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.incomes.map(
                        (item)=>(
                          <tr>
                          <td>{item.incomecategory}</td>
                          <td>{<CurrencyFormat value={item.totalPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalincomes} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table>   

            <br></br> 
            <Divider style={{borderColor:'red'}} dashed />
            <h4>EXPENSES</h4>

            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Expense Category</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.expenses.map(
                        (item)=>(
                          <tr>
                          <td>{item.expensecategory}</td>
                          <td>{<CurrencyFormat value={item.totalPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalexpenses} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                        <tr>
                        <td style={{fontWeight:'bolder',fontSize:20}}>SURPLUS</td>
                        <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.props.surplus} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table> 

                <Divider style={{borderColor:'black'}} dashed />

            <reactstrp.Table bordered>
                
                <tbody>
                  <tr>
                  <td style={{fontWeight:'bolder',fontSize:20}}>TOTAL MEMBER SAVINGS</td>
                  <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.props.extra_data.total_savings} displayType={'text'} thousandSeparator={true}/>}</td>
                  </tr>

                  <tr>
                  <td style={{fontWeight:'bolder',fontSize:20}}>TOTAL SHARES PURCHASED</td>
                  <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.props.extra_data.total_shares} displayType={'text'} thousandSeparator={true}/>}</td>
                  </tr>

                  <tr>
                  <td style={{fontWeight:'bolder',fontSize:20}}>TOTAL LOANS DISBURSED</td>
                  <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.props.extra_data.total_loan_issuances} displayType={'text'} thousandSeparator={true}/>}</td>
                  </tr>

                  <tr>
                  <td style={{fontWeight:'bolder',fontSize:20}}>TOTAL LOANS REPAID (PRINCIPLE)</td>
                  <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.props.extra_data.total_loan_repayment_rinciple} displayType={'text'} thousandSeparator={true}/>}</td>
                  </tr>

                  <tr>
                  <td style={{fontWeight:'bolder',fontSize:20}}>TOTAL SAVINGS WITHDRAWN</td>
                  <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.props.extra_data.total_savings_withdrawn} displayType={'text'} thousandSeparator={true}/>}</td>
                  </tr>

                  <tr>
                  <td style={{fontWeight:'bolder',fontSize:20}}>NUMBER OF NEW MEMBERS</td>
                  <td style={{fontWeight:'bolder',fontSize:20}}>{this.props.extra_data.new_members_total}</td>
                  </tr>

              </tbody>
          </reactstrp.Table> 



        </div>
      );
    }
  }




class IncomeStatement_periodic_summaryReport extends React.Component {

  state = {
   expenses:[],
   incomes:[],
   dateone:'',
   datetwo:'',
   totalincomes:0,
   totalexpenses:0,
   surplus:0,
   datarequested:false,
   extra_data:{},

   expenses_list:[],
   incomes_list:[],
   deposts_list:[],
   shares_list:[],
   withdraws_list:[],
   loan_issuances_list:[],
   principle_list:[],
   newmbers_list:[],

   detailsmodel_visible:false,
   show_expenses:false,
   show_incomes:false,
   show_deposits:false,
   show_shares:false,
   show_withdraws:false,
   showloan_issuances:false,
   showprinciple_pay:false,
   show_newmebers:false



  };

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

  }

  handledatechange= (date, dateString) =>{
    this.setState({ dateone: dateString[0]});
    this.setState({ datetwo: dateString[1]});
  } 

  //search incomes method
  getstatement=()=>{
   let form_data = new FormData();
   form_data.append('dateone', this.state.dateone);
   form_data.append('datetwo', this.state.datetwo);
   form_data.append('sacco', sacco);
   
   if(this.state.dateone===''||this.state.datetwo===''){
    alert("Please are dates missing")
   }else{
    this.setState({datarequested:true})

       //Now submit sale data to database
       axios.post(serverconfig.backendserverurl+'/customqueries/getincomestatement_periodic_summary', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
         })
         .then(res =>{
           this.setState({datarequested:false})
 
             this.setState({expenses:JSON.parse(res.data.expensereport)})
             this.setState({incomes:JSON.parse(res.data.incomesreport)})
             this.setState({totalincomes:res.data.totalincomes})
             this.setState({totalexpenses:res.data.totalexpenses})
             this.setState({surplus:res.data.surplus})

             //get the extar data
             this.setState({
              extra_data:{
                "new_members_total":res.data.new_members_total,
                "total_savings_withdrawn":res.data.total_savings_withdrawn,
                "total_loan_repayment_rinciple":res.data.total_loan_repayment_rinciple,
                "total_loan_issuances":res.data.total_loan_issuances,
                "total_shares":res.data.total_shares,
                "total_savings":res.data.total_savings,                
              }
             })


             this.setState({expenses_list:JSON.parse(res.data.expenses_list)})
             this.setState({incomes_list:JSON.parse(res.data.incomes_list)})
             this.setState({deposts_list:JSON.parse(res.data.deposts_list)})
             this.setState({shares_list:JSON.parse(res.data.shares_list)})
             this.setState({withdraws_list:JSON.parse(res.data.withdraws_list)})
             this.setState({loan_issuances_list:JSON.parse(res.data.loan_issuances_list)})
             this.setState({principle_list:JSON.parse(res.data.principle_list)})
             this.setState({newmbers_list:JSON.parse(res.data.newmbers_list)})


         } 
         )
         .catch(error => console.log(error)) 
    }

   }



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
      
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <div style={{display: "none"}}>
             &nbsp;&nbsp;
            <ReportToPrint
            totalincomes={this.state.totalincomes} 
            totalexpenses={this.state.totalexpenses} 
            surplus={this.state.surplus} 
            dateone={this.state.dateone}
            datetwo={this.state.datetwo}
            incomes={this.state.incomes}
            expenses={this.state.expenses}
            extra_data={this.state.extra_data}

            ref={el => (this.componentRef = el)} /> 

          </div>

          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="PERIODIC PERFORMANCE SUMMARY REPORT " key="1">
            <Form  layout="inline" >
                <FormItem label="Date Range">
                    <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                </FormItem>

                <FormItem>
                <Button onClick={this.getstatement} type="primary" htmlType="button">Search</Button>
                </FormItem> 

           </Form>

           <br></br>
           <h4>INCOMES </h4>
            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Income Category</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.incomes.map(
                        (item)=>(
                          <tr>
                          <td>{item.incomecategory}</td>
                          <td>{<CurrencyFormat value={item.totalPaid} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total &nbsp; <Button onClick={()=>{
                          this.setState({detailsmodel_visible:true}) 
                          this.setState({show_incomes:true})
                      
                      }}>View details</Button></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalincomes} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table>   

            <br></br> 
            <Divider style={{borderColor:'black'}} dashed />
            <h4>EXPENSES</h4>

            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Expense Category</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.expenses.map(
                        (item)=>(
                          <tr>
                          <td>{item.expensecategory}</td>
                          <td>{<CurrencyFormat value={item.totalPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total &nbsp; <Button onClick={()=>{
                           this.setState({detailsmodel_visible:true}) 
                           this.setState({show_expenses:true})
                        }}>View details</Button></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalexpenses} displayType={'text'} thousandSeparator={true}/>}</td>

                        </tr>

                        <tr>
                        <td style={{fontWeight:'bolder',fontSize:20}}>SURPLUS / DEFICIT</td>
                        <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.state.surplus} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>
                    </tbody>
                </reactstrp.Table> 
            <Divider style={{borderColor:'black'}} dashed />
            <reactstrp.Table bordered>
                
                <tbody>
                  <tr>
                  <td style={{fontWeight:'bolder',fontSize:20}}>TOTAL MEMBER SAVINGS</td>
                  <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.state.extra_data.total_savings} displayType={'text'} thousandSeparator={true}/>}</td>
                  <td><Button onClick={()=>{
                      this.setState({detailsmodel_visible:true}) 
                      this.setState({show_deposits:true})
                  }}>View details</Button></td>

                  </tr>

                  <tr>
                  <td style={{fontWeight:'bolder',fontSize:20}}>TOTAL SHARES PURCHASED</td>
                  <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.state.extra_data.total_shares} displayType={'text'} thousandSeparator={true}/>}</td>
                  <td><Button onClick={()=>{
                      this.setState({detailsmodel_visible:true}) 
                      this.setState({show_shares:true})
                  }}>View details</Button></td>

                  </tr>

                  <tr>
                  <td style={{fontWeight:'bolder',fontSize:20}}>TOTAL LOANS DISBURSED</td>
                  <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.state.extra_data.total_loan_issuances} displayType={'text'} thousandSeparator={true}/>}</td>
                  <td><Button onClick={()=>{
                     this.setState({detailsmodel_visible:true}) 
                     this.setState({showloan_issuances:true})
                  }}>View details</Button></td>

                  </tr>

                  <tr>
                  <td style={{fontWeight:'bolder',fontSize:20}}>TOTAL LOANS REPAID (PRINCIPLE)</td>
                  <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.state.extra_data.total_loan_repayment_rinciple} displayType={'text'} thousandSeparator={true}/>}</td>
                  <td><Button onClick={()=>{
                     this.setState({detailsmodel_visible:true}) 
                     this.setState({showprinciple_pay:true})


                  }}>View details</Button></td>

                  </tr>

                  <tr>
                  <td style={{fontWeight:'bolder',fontSize:20}}>TOTAL SAVINGS WITHDRAWN</td>
                  <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.state.extra_data.total_savings_withdrawn} displayType={'text'} thousandSeparator={true}/>}</td>
                  <td><Button onClick={()=>{
                     this.setState({detailsmodel_visible:true}) 
                     this.setState({show_withdraws:true})

                  }}>View details</Button></td>
 
                  </tr>

                  <tr>
                  <td style={{fontWeight:'bolder',fontSize:20}}>NUMBER OF NEW MEMBERS</td>
                  <td style={{fontWeight:'bolder',fontSize:20}}>{this.state.extra_data.new_members_total}</td>
                  <td><Button onClick={()=>{
                      this.setState({detailsmodel_visible:true}) 
                      this.setState({show_newmebers:true})
                  }}>View details</Button></td>
                  </tr>

              </tbody>
          </reactstrp.Table> 


          <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>

          <ReactToPrint
          trigger={() => 
          <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
          Print
          </Button>
          }
            content={() => this.componentRef}
          />
          </div>


            </Panel>
            </Collapse>

            <Modal
              visible={this.state.detailsmodel_visible}
              title="Details section Form"
              onCancel={()=>{
                this.setState({detailsmodel_visible:false})

                this.setState({show_expenses:false})
                this.setState({show_incomes:false})
                this.setState({show_deposits:false})
                this.setState({show_shares:false})
                this.setState({show_withdraws:false})
                this.setState({showloan_issuances:false})
                this.setState({showprinciple_pay:false})
                this.setState({show_newmebers:false})
                
              }}
              footer={[
                <Button key="back" onClick={()=>{
                  this.setState({detailsmodel_visible:false})

                  this.setState({show_expenses:false})
                  this.setState({show_incomes:false})
                  this.setState({show_deposits:false})
                  this.setState({show_shares:false})
                  this.setState({show_withdraws:false})
                  this.setState({showloan_issuances:false})
                  this.setState({showprinciple_pay:false})
                  this.setState({show_newmebers:false})



                }}>
                  Cancel
                </Button>,
                ]}
                >

                  {
                    this.state.show_expenses===true && this.state.expenses_list.length>0?
                    <div>

                    <ExcelFile 
                    filename={"Expenses list from "+this.state.dateone +" to "+this.state.datetwo}
                    element={
                    <Button type='primary' icon={<DownloadOutlined/>} 
                    style={{margin:10}}> Download Excel report
                            </Button>}>
                      <ExcelSheet data={this.state.expenses_list} name={"Expense list"}>
                          <ExcelColumn label="Date" value="date"/>
                          <ExcelColumn label="Expense item" value="expense_item"/>
                          <ExcelColumn label="Total" value="total_to_be_paid"/>

                      </ExcelSheet>
                    </ExcelFile>



                     <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Item</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>

                      {
                        this.state.expenses_list.map((item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.expense_item}</td>
                          <td>{<CurrencyFormat value={item.total_to_be_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))
                      }
                        </tbody>
                      </reactstrp.Table>  
                    </div>
                    :
                    null
                  }

              {
                    this.state.show_incomes===true && this.state.incomes_list.length>0?
                    <div>

                    <ExcelFile 
                    filename={"Incomes list from "+this.state.dateone +" to "+this.state.datetwo}
                    element={
                    <Button type='primary' icon={<DownloadOutlined/>} 
                    style={{margin:10}}> Download Excel report
                            </Button>}>
                      <ExcelSheet data={this.state.incomes_list} name={"Income list"}>
                          <ExcelColumn label="Date" value="date"/>
                          <ExcelColumn label="Income item" value="income_item"/>
                          <ExcelColumn label="Total" value="total_to_be_paid"/>

                      </ExcelSheet>
                    </ExcelFile>



                     <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Item</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>

                      {
                        this.state.incomes_list.map((item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.income_item}</td>
                          <td>{<CurrencyFormat value={item.total_to_be_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))
                      }
                        </tbody>
                      </reactstrp.Table>  
                    </div>
                    :
                    null
                  }


              {
                    this.state.show_deposits===true && this.state.deposts_list.length>0?
                    <div>
                   
                   <ExcelFile 
                    filename={"Saving deposits from "+this.state.dateone +" to "+this.state.datetwo}
                    element={
                    <Button type='primary' icon={<DownloadOutlined/>} 
                    style={{margin:10}}> Download Excel report
                            </Button>}>
                      <ExcelSheet data={this.state.deposts_list} name={"Savings deposits"}>
                          <ExcelColumn label="Date" value="date"/>
                          <ExcelColumn label="Account name" value="accountname"/>
                          <ExcelColumn label="Account number" value="accountnumber"/>
                          <ExcelColumn label="Amount" value="amount"/>

                      </ExcelSheet>
                    </ExcelFile>                   
                   
                   
                   
                   
                     <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Account name</th>
                          <th>Account number</th>
                        </tr>
                      </thead>
                      <tbody>

                      {
                        this.state.deposts_list.map((item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{<CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{item.accountname}</td>
                          <td>{item.accountnumber}</td>

                          </tr>
                        ))
                      }
                        </tbody>
                      </reactstrp.Table>  
                    </div>
                    :
                    null
                  }

             
              {
                    this.state.show_shares===true && this.state.shares_list.length>0?
                    <div>

                <ExcelFile 
                    filename={"Share purchases from "+this.state.dateone +" to "+this.state.datetwo}
                    element={
                    <Button type='primary' icon={<DownloadOutlined/>} 
                    style={{margin:10}}> Download Excel report
                            </Button>}>
                      <ExcelSheet data={this.state.shares_list} name={"Shares purchases"}>
                          <ExcelColumn label="Date" value="date"/>
                          <ExcelColumn label="Account name" value="accountname"/>
                          <ExcelColumn label="Account number" value="accountnumber"/>
                          <ExcelColumn label="Amount" value="amount"/>

                      </ExcelSheet>
                    </ExcelFile>                   
                   



                     <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Account name</th>
                          <th>Account number</th>
                        </tr>
                      </thead>
                      <tbody>

                      {
                        this.state.shares_list.map((item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{<CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{item.accountname}</td>
                          <td>{item.accountnumber}</td>

                          </tr>
                        ))
                      }
                        </tbody>
                      </reactstrp.Table>  
                    </div>
                    :
                    null
                  } 

              {
                    this.state.show_withdraws===true && this.state.withdraws_list.length>0?
                    <div>
                   
                   <ExcelFile 
                    filename={"Savings withdraws from "+this.state.dateone +" to "+this.state.datetwo}
                    element={
                    <Button type='primary' icon={<DownloadOutlined/>} 
                    style={{margin:10}}> Download Excel report
                            </Button>}>
                      <ExcelSheet data={this.state.withdraws_list} name={"Savings withdraws"}>
                          <ExcelColumn label="Date" value="date"/>
                          <ExcelColumn label="Account name" value="accountname"/>
                          <ExcelColumn label="Account number" value="accountnumber"/>
                          <ExcelColumn label="Amount" value="amount"/>

                      </ExcelSheet>
                    </ExcelFile>                   
                   
                   
                   
                     <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Account name</th>
                          <th>Account number</th>
                        </tr>
                      </thead>
                      <tbody>

                      {
                        this.state.withdraws_list.map((item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{<CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{item.accountname}</td>
                          <td>{item.accountnumber}</td>

                          </tr>
                        ))
                      }
                        </tbody>
                      </reactstrp.Table>  
                    </div>
                    :
                    null
                  } 

            {
                    this.state.showloan_issuances===true && this.state.loan_issuances_list.length>0?
                    <div>
                     
                     <ExcelFile 
                    filename={"Loan disbursements from "+this.state.dateone +" to "+this.state.datetwo}
                    element={
                    <Button type='primary' icon={<DownloadOutlined/>} 
                    style={{margin:10}}> Download Excel report
                            </Button>}>
                      <ExcelSheet data={this.state.loan_issuances_list} name={"Loan disbursements "}>
                          <ExcelColumn label="Date" value="date"/>
                          <ExcelColumn label="Account name" value="accountname"/>
                          <ExcelColumn label="Account number" value="accountnumber"/>
                          <ExcelColumn label="Amount" value="amount"/>

                      </ExcelSheet>
                    </ExcelFile>                       
                     
                     
                     <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Account name</th>
                          <th>Account number</th>
                        </tr>
                      </thead>
                      <tbody>

                      {
                        this.state.loan_issuances_list.map((item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{<CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{item.accountname}</td>
                          <td>{item.accountnumber}</td>

                          </tr>
                        ))
                      }
                        </tbody>
                      </reactstrp.Table>  
                    </div>
                    :
                    null
                  } 


                {
                    this.state.showprinciple_pay===true && this.state.principle_list.length>0?
                    <div>
                     
                     <ExcelFile 
                    filename={"Loan principle payments from "+this.state.dateone +" to "+this.state.datetwo}
                    element={
                    <Button type='primary' icon={<DownloadOutlined/>} 
                    style={{margin:10}}> Download Excel report
                            </Button>}>
                      <ExcelSheet data={this.state.principle_list} name={"Loan principle payments "}>
                          <ExcelColumn label="Date" value="date"/>
                          <ExcelColumn label="Account name" value="accountname"/>
                          <ExcelColumn label="Account number" value="accountnumber"/>
                          <ExcelColumn label="Amount" value="amount"/>
                          <ExcelColumn label="Loan ID" value="loanid"/>

                      </ExcelSheet>
                    </ExcelFile>                     
                     
                     
                     
                     <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Account name</th>
                          <th>Account number</th>
                          <th>Loan ID</th>
                        </tr>
                      </thead>
                      <tbody>

                      {
                        this.state.principle_list.map((item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{<CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{item.accountname}</td>
                          <td>{item.accountnumber}</td>
                          <td>{item.loanid}</td>
                          </tr>
                        ))
                      }
                        </tbody>
                      </reactstrp.Table>  
                    </div>
                    :
                    null
                  }



                  {
                    this.state.show_newmebers===true && this.state.newmbers_list.length>0?
                    <div>

                   <ExcelFile 
                    filename={"New Member's list  from "+this.state.dateone +" to "+this.state.datetwo}
                    element={
                    <Button type='primary' icon={<DownloadOutlined/>} 
                    style={{margin:10}}> Download Excel report
                            </Button>}>
                      <ExcelSheet data={this.state.newmbers_list} name={"New Member's list"}>
                          <ExcelColumn label="Date of joining" value="DateOfJoining"/>
                          <ExcelColumn label="Account name" value="accountname"/>
                          <ExcelColumn label="Account number" value="accountnumber"/>

                      </ExcelSheet>
                    </ExcelFile>                     
                                          
                     
                     
                     
                     <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Account name</th>
                          <th>Account number</th>
                        </tr>
                      </thead>
                      <tbody>

                      {
                        this.state.newmbers_list.map((item)=>(
                          <tr>
                          <td>{item.DateOfJoining}</td>
                          <td>{item.accountname}</td>
                          <td>{item.accountnumber}</td>
                          </tr>
                        ))
                      }
                        </tbody>
                      </reactstrp.Table>  
                    </div>
                    :
                    null
                  }
          </Modal>
        </div>
    )

    }

  }
}

export default IncomeStatement_periodic_summaryReport; 
