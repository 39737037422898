import React from 'react';
import AccountTypeForm from '../components/AccountTypeForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Card,Tabs } from 'antd';

import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");
const { TabPane } = Tabs;

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

var sacco= ''
var token= ''

class MobileMoneyActions extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    mobile_collections: [],
    datarequested:true,
    comission_account:{},
    pay_account:{},
    mobile_moneypayments:[],
    collections_withdraws_transfers:[],
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data = new FormData();
    form_data.append('sacco', sacco);

    //Now submit sale data to database
    axios.post(serverconfig.backendserverurl+'/customqueries/getmobilemoneyaccounts', form_data,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res =>{
      console.log(res.data)
      this.setState({comission_account:res.data.commissionaccount})
      this.setState({pay_account:res.data.payaccount})
      this.setState({datarequested:false})
    } 
    )
    .catch(error => console.log(error)) 

    //get mobile collections
    axios.get(serverconfig.backendserverurl+`/api/mobile_collections/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          mobile_collections:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/mobile_moneypayments/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          mobile_moneypayments:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/collections_withdraws_transfers/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          collections_withdraws_transfers:res.data
        })
    })

}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/accounttypes/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

    const collection_columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Account name',
        dataIndex: 'accountname',
        key: 'id',
        ...this.getColumnSearchProps('accountname'),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'id',
        ...this.getColumnSearchProps('phone'),
      },
      {
        title: 'Network reference',
        dataIndex: 'network_ref',
        key: 'id',
        ...this.getColumnSearchProps('network_ref'),
      },

    ];


    const payments_columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Account/Phone number',
        dataIndex: 'account_or_number',
        key: 'id',
        ...this.getColumnSearchProps('account_or_number'),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'Method of payment',
        dataIndex: 'method',
        key: 'id',
        ...this.getColumnSearchProps('method'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'id',
        ...this.getColumnSearchProps('status'),
      },
      {
        title: 'Details',
        dataIndex: 'details',
        key: 'id',
        ...this.getColumnSearchProps('details'),
      },

    ];


    const transfer_columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'id',
        ...this.getColumnSearchProps('status'),
      },
      {
        title: 'Details',
        dataIndex: 'details',
        key: 'id',
        ...this.getColumnSearchProps('details'),
      },

    ];




    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <Card>
          <h3>Mobile Money wallet details:</h3>
          <h4 style={{color:'green'}}>Total collections:  {<CurrencyFormat value={this.state.comission_account.current_balance} displayType={'text'} thousandSeparator={true}/>}</h4>
          <h4 style={{color:'green'}}>Total of disbursements & Withdraws: {<CurrencyFormat value={this.state.pay_account.current_balance} displayType={'text'} thousandSeparator={true}/>}</h4>

          </Card>
          <br></br>
          <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Collections" key="03" >
              <Table   
              columns={collection_columns} 
              scroll={{ x: 1000 }}
              dataSource={this.state.mobile_collections} 
              pagination={{showQuickJumper:true,showSizeChanger:true }}
              bordered/>
            </TabPane>

            <TabPane tab="Collections transfers to Withdraws" key="02" >
              <Table   
                columns={transfer_columns} 
                scroll={{ x: 1000 }}
                dataSource={this.state.collections_withdraws_transfers} 
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                bordered/>

            </TabPane>

            <TabPane tab="Disbursements and withdraws" key="01" >
              <Table   
                columns={payments_columns} 
                scroll={{ x: 1000 }}
                dataSource={this.state.mobile_moneypayments} 
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                bordered/>
            </TabPane>

          </Tabs>
        </div>
        )

    }

    
  }
}

export default MobileMoneyActions; 
