import React from 'react';
import { Form, Input, Button,Select,Spin,Result,message,Card,Switch,InputNumber,DatePicker } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;


var sacco= ''
var username=''
var token= ''

class LoanIssuanceForm extends React.Component {

  state = {
    approved_amount:'',
    loan_pass:false,
    approval_report:'',
    loanapplication:{},
    loanapproval:{},
    datasubmittedsuccessfully:false,
    datarequested:true,
    interestratetype:'',
    loanperiod:0,
    dateofissuance:moment(),
    dateofissuancestring:moment().format(dateFormat).toString(),

    staffs:[],
    creditofficer:'',

    debit_loanaccount:false,
    companyprofile:{},
    companyaccounts: [],
    creditaccount:''

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }
    

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    const loanapplicationID=this.props.match.params.loanapplicationID;

    axios.get(`${serverconfig.backendserverurl}/api/loanapplications/${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapplication: res.data})
        this.setState({approved_amount:res.data.loan_amount})
    })


    axios.get(`${serverconfig.backendserverurl}/api/loanapprovals/?loan_id=${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapproval: res.data[0]})
    })

    axios.get(serverconfig.backendserverurl+`/api/staffs/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          staffs:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })
        //this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

  }

  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    if(this.state.companyprofile.attach_officer_to_loan===true &&this.state.creditofficer===''){
      message.error("Please select credit officer")
    }else{

      const loanapplicationID=this.props.match.params.loanapplicationID;

      this.setState({datarequested:true})
      let form_data = new FormData();
      form_data.append('dateofissuance', this.state.dateofissuancestring);
      form_data.append('loan_id', loanapplicationID);
      form_data.append('user',username );
      form_data.append('interestratetype',this.state.interestratetype );
      form_data.append('loanperiod',this.state.loanapproval.approved_period );
      form_data.append('sacco', sacco);
  
      form_data.append('debit_loanaccount', this.state.debit_loanaccount);
      form_data.append('creditaccount', this.state.creditaccount);

      if(this.state.companyprofile.attach_officer_to_loan===true){
       form_data.append('attachedloanofficer', "true");
       form_data.append('loan_officer', this.state.creditofficer);
      }else{
        form_data.append('attachedloanofficer', "false");
        form_data.append('loan_officer', '');
      }
      
      axios.post(serverconfig.backendserverurl+'/customqueries/createloanissuance', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>  {
      if(res.data.message=="success"){
        message.info(res.data.message)
        this.setState({datarequested:false})
        this.setState({datasubmittedsuccessfully:true})
  
      }else{
        message.info("Some thing went wrong")
      }
  
    }       
  
    )
    .catch(error => console.log(error))
    }

  }


  handleinterestratetypeChange= (e) => this.setState({ interestratetype: e});
  handleloanperiodChange= (e) => this.setState({ loanperiod: e.target.value});

  handledateofissuanceChange= (date, dateString) =>{
    //console.log(date.format(dateFormat))
    this.setState({ dateofissuance: date.format(dateFormat)});
    this.setState({dateofissuancestring:dateString})
  } 


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <Result
            status="success"
            title="Successfully Done Loan Disbursement Or Issuance "
            subTitle="Now Loan can be found in disbursed loans for repayments and review"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />

            </Card>
            </Col>
            </Row>
        );

      }else{
        return (
          <div>
              <Row >
                    <Col xs="12" sm="6" lg="6">
                      <Card>
                      <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN ISSUANCE FORM</h4>
                     
                     {
                       this.state.loanapplication.issued?
                       null:
                       <Form
                       onFinish={(event) => this.handleFormSubmit(event)} 
                     >
 

                      <FormItem label="Date Of Issuance"
                        name="dateofissuance"          
                     >
                          <DatePicker defaultValue={moment()} onChange={this.handledateofissuanceChange} format={dateFormat} />
                      </FormItem>


                      <FormItem label="Debit Loan Account?" >
                          <Switch
                              checked={this.state.debit_loanaccount}
                              onChange={val=>{this.setState({debit_loanaccount:val})}}
                              checkedChildren="True"
                              unCheckedChildren="False"
                              />
                      </FormItem>


                      {
                        this.state.companyprofile.attach_officer_to_loan===true?
                        <FormItem label="Credit Officer"
                        name="creditofficer"
                        rules={[
                          {
                            required: false,
                            message: 'Please Select creditofficer',
                          },
                        ]}
                      >
                          <Select placeholder="Credit Officer"  value={this.state.creditofficer} onChange={(val)=>{this.setState({creditofficer:val})}} >
                            {this.state.staffs.map(
                              (ctgry)=>(
                                <Option value={ctgry.id}>{ctgry.full_Name}</Option>
                              ))}
                          </Select>
                      </FormItem>
                        :
                        null
                      }

                  {
                        this.state.debit_loanaccount===true?
                        <FormItem label={<h4 style={{fontWeight:'bold'}}>Paying A/C (Debit A/C).</h4>}
                        name="creditaccount"
                        rules={[
                          {
                            required: true,
                            message: 'Please select account',
                          },
                        ]}
                       >
                            <Select 
                            placeholder="Account" 
                            style={{  }} 
                            value={this.state.creditaccount}
                            onChange={(val)=>{this.setState({creditaccount:val})}} 
                            showSearch
                            optionFilterProp="children"
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onSearch={this.onSearch}                    
                            >
                              {this.state.companyaccounts.map(
                                (accnt)=>(
                                  <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                                ))}
                            </Select>
                        </FormItem>
                        :
                        null
                      }


                       <FormItem>
                         <Button  type="primary" htmlType="submit">Submit</Button>
                       </FormItem>
                     </Form>
                     }
                     
                    
                      </Card>
                      </Col>
  
  
                      <Col xs="12" sm="6" lg="6">
                      <Card>
                      <h4 style={{fontStyle:'oblique',fontWeight:'bolder',color:'green'}}>Loan Approval Details</h4>
                      <h6 style={{fontWeight:'bold'}}>Appoval Report Details: {this.state.loanapproval.report_details}</h6>

                      <br></br>
                      <h4 style={{fontWeight:'bolder'}}>Loan Details</h4>
                      <h6>Account No: {this.state.loanapplication.accountNumber}</h6>
                      <h6>Account Name: {this.state.loanapplication.accountName}</h6>
                      <h6 >Loan Amount:<CurrencyFormat value={this.state.loanapplication.loan_amount} displayType={'text'} thousandSeparator={true}  /> UGX</h6>
                      <h6>Interest Rate:{this.state.loanapplication.interestRate} </h6>

                      </Card>
                      </Col>
              </Row>
  
          </div>
        );

      }

     
    }


    
  }

}


export default LoanIssuanceForm;



