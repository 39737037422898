import React from 'react';
import { Form, Input, Button,Spin,DatePicker } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''
var sacco= ''

const dateFormat = 'DD/MM/YYYY';

class LoanFineAttachmentForm extends React.Component {

  state = {
    due_date:'',
    unpaid_interest:0,
    unpaid_principle:0,
    fine_attached:0,
    datarequested:false,
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('due_date', this.state.due_date);
    form_data.append('unpaid_interest', this.state.unpaid_interest);
    form_data.append('unpaid_principle', this.state.unpaid_principle);
    form_data.append('fine_attached', this.state.fine_attached);
    form_data.append('sacco', sacco);
    form_data.append('loanissue', this.props.loan_id);

      axios.post(serverconfig.backendserverurl+'/api/loan_manual_fines/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }

  handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
      <div>
        <h4>Attach new fine to loan</h4>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >

        <FormItem label="Due date"
            name='date'
            rules={[
              {
                required: true,
                message: 'Please Select date',
              },
            ]}
          >
              <DatePicker value={moment()} onChange={(date, dateString) => this.setState({ due_date: dateString})} format={dateFormat} />
          </FormItem>

          <FormItem label="Unpaid Principle">
            <Input name="unpaid_principle" type='number'  placeholder="Put principle here." value={this.state.unpaid_principle} onChange={(val)=>{this.setState({unpaid_principle:val.target.value})}} />
          </FormItem>

          <FormItem label="Unpaid interest">
            <Input name="unpaid_interest" type='number'  placeholder="Put interest here." value={this.state.unpaid_interest} onChange={(val)=>{this.setState({unpaid_interest:val.target.value})}} />
          </FormItem>

          <FormItem label="Fine amount"
            name="fine_attached"
            rules={[
              {
                required: true,
                message: 'Please input fine',
              },
            ]}
          >
            <Input name="fine_attached" type='number'  placeholder="Put fine here." value={this.state.fine_attached} onChange={(val)=>{this.setState({fine_attached:val.target.value})}} />
          </FormItem>

      <FormItem>
        <Button  type="primary" htmlType="submit">Submit</Button>
      </FormItem>

      </Form>
        </div>
      );

    }
    
  }

}


export default LoanFineAttachmentForm;



