import React from 'react';
import axios from 'axios';
import { Card,Avatar} from 'antd';
import { Form, Input, Button,Select,Spin } from 'antd';
import {
    Col,
    Row,
  } from 'reactstrap';
import AssetForm from '../components/AssetForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class AccountTypeDetail extends React.Component{

    state={
        accountype: {},
        account_type_name:'',
        minimum_account_balance:0,
        deposit_charges:0,
        minimum_account_deposit:0,
        withdraw_charges:0,
        withdrawals_per_month:0,
        account_fine_fee:0,
        account_transfer_charge:0,
        account_monthly_charge:0,
        account_monthly_interest_rate:0,
        account_statement_fee:0,
        cheque_deposit_fee:0,
        reactivation_charge:0,
        datarequested:true,
        number_ofday_todormancy_sincelasttransaction:0,
        agelimit_months_cap:0,
        member_registrationfees:0,
        minimum_sharesforactivation:0,
    }
    


    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }

        const accountTypeID=this.props.match.params.accountTypeID;
        axios.get(`${serverconfig.backendserverurl}/api/accounttypes/${accountTypeID}`)
        .then(res => {  
            this.setState({accountype: res.data})

            this.setState({account_type_name: res.data.account_type_name})
            this.setState({minimum_account_balance: res.data.minimum_account_balance})
            this.setState({deposit_charges: res.data.deposit_charges})
            this.setState({minimum_account_deposit: res.data.minimum_account_deposit})
            this.setState({withdraw_charges: res.data.withdraw_charges})
            this.setState({withdrawals_per_month: res.data.withdrawals_per_month})
            this.setState({account_fine_fee: res.data.account_fine_fee})
            this.setState({account_transfer_charge: res.data.account_transfer_charge})
            this.setState({account_monthly_charge: res.data.account_monthly_charge})
            this.setState({account_monthly_interest_rate: res.data.account_monthly_interest_rate})
            this.setState({account_statement_fee: res.data.account_statement_fee})
            this.setState({cheque_deposit_fee: res.data.cheque_deposit_fee})
            this.setState({reactivation_charge: res.data.reactivation_charge})
            this.setState({number_ofday_todormancy_sincelasttransaction: res.data.number_ofday_todormancy_sincelasttransaction})

            this.setState({agelimit_months_cap: res.data.agelimit_months_cap})

            this.setState({member_registrationfees: res.data.member_registrationfees})
            this.setState({minimum_sharesforactivation: res.data.minimum_sharesforactivation})

            this.setState({datarequested:false})

        })
    }

    //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    const accountTypeID=this.props.match.params.accountTypeID;
    let form_data = new FormData();

    form_data.append('account_type_name', this.state.account_type_name);
    form_data.append('minimum_account_balance', this.state.minimum_account_balance);
    form_data.append('deposit_charges', this.state.deposit_charges);
    form_data.append('minimum_account_deposit', this.state.minimum_account_deposit);
    form_data.append('withdraw_charges', this.state.withdraw_charges);
    form_data.append('withdrawals_per_month', this.state.withdrawals_per_month);
    form_data.append('account_fine_fee', this.state.account_fine_fee);
    form_data.append('account_transfer_charge', this.state.account_transfer_charge);
    form_data.append('account_monthly_charge', this.state.account_monthly_charge);
    form_data.append('account_monthly_interest_rate', this.state.account_monthly_interest_rate);
    form_data.append('account_statement_fee', this.state.account_statement_fee);
    form_data.append('cheque_deposit_fee', this.state.cheque_deposit_fee);
    form_data.append('reactivation_charge', this.state.reactivation_charge);
    form_data.append('number_ofday_todormancy_sincelasttransaction', this.state.number_ofday_todormancy_sincelasttransaction);
    form_data.append('agelimit_months_cap', this.state.agelimit_months_cap);

    form_data.append('member_registrationfees', this.state.member_registrationfees);
    form_data.append('minimum_sharesforactivation', this.state.minimum_sharesforactivation);


    axios.put(serverconfig.backendserverurl+`/api/accounttypes/${accountTypeID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>this.setState({datarequested:false}),window.location.reload(false)

  )
  .catch(error => console.log(error))
  }


  handleaccount_type_nameChange= (e) => this.setState({ account_type_name: e.target.value});
  handleminimum_account_balanceChange= (e) => this.setState({ minimum_account_balance: e.target.value});
  handledeposit_chargesChange= (e) => this.setState({ deposit_charges: e.target.value});
  handleminimum_account_depositChange= (e) => this.setState({ minimum_account_deposit: e.target.value});
  handlewithdraw_chargesChange= (e) => this.setState({ withdraw_charges: e.target.value});
  handlewithdrawals_per_monthChange= (e) => this.setState({ withdrawals_per_month: e.target.value});
  handleaccount_fine_feeChange= (e) => this.setState({ account_fine_fee: e.target.value});
  handleaccount_transfer_chargeChange= (e) => this.setState({ account_transfer_charge: e.target.value});
  handleaccount_monthly_chargeChange= (e) => this.setState({ account_monthly_charge: e.target.value});
  handleaccount_monthly_interest_rateChange= (e) => this.setState({ account_monthly_interest_rate: e.target.value});
  handleaccount_statement_feeChange= (e) => this.setState({ account_statement_fee: e.target.value});
  handlecheque_deposit_feeChange= (e) => this.setState({ cheque_deposit_fee: e.target.value});


    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{

      return(
          <div>

        <Card 
          style={{padding:10}}
          hoverable>
            <h4>Account Type Details / Update</h4>
            <Form
                onFinish={(event) => this.handleFormSubmit(event)} 
                >
              <FormItem label="Account Type Name">
              <Input name="account_type_name"  placeholder="Put a name here." value={this.state.account_type_name} onChange={this.handleaccount_type_nameChange} />
            </FormItem>

            <FormItem label="Minimum Account Balance">
              <Input name="minimum_account_balance"  placeholder="0.00" value={this.state.minimum_account_balance} onChange={this.handleminimum_account_balanceChange} />
            </FormItem>
  
            <FormItem label="Deposit Charges">
              <Input name="deposit_charges"  placeholder="0.00" value={this.state.deposit_charges} onChange={this.handledeposit_chargesChange} />
            </FormItem>
  
            <FormItem label="Minimum Account Deposit">
              <Input name="minimum_account_deposit"  placeholder="0.00" value={this.state.minimum_account_deposit} onChange={this.handleminimum_account_depositChange} />
            </FormItem>


            <FormItem label="Withdraw Charges">
              <Input name="withdraw_charges"  placeholder="0.00" value={this.state.withdraw_charges} onChange={this.handlewithdraw_chargesChange} />
            </FormItem>

            <FormItem label="Withdrawals per month">
              <Input name="withdrawals_per_month"  placeholder="0" value={this.state.withdrawals_per_month} onChange={this.handlewithdrawals_per_monthChange} />
            </FormItem>

            <FormItem label="Account Fine Fee">
              <Input name="account_fine_fee"  placeholder="0" value={this.state.account_fine_fee} onChange={this.handleaccount_fine_feeChange} />
            </FormItem>

            <FormItem label="Account Transfer Charge">
              <Input name="account_transfer_charge"  placeholder="0.00" value={this.state.account_transfer_charge} onChange={this.handleaccount_transfer_chargeChange} />
            </FormItem>

            <FormItem label="Account Monthly Charge">
              <Input name="account_monthly_charge"  placeholder="0.00" value={this.state.account_monthly_charge} onChange={this.handleaccount_monthly_chargeChange} />
            </FormItem>

            <FormItem label="Account Monthly Interest Rate"
            >
              <Input name="account_monthly_interest_rate"  placeholder="0.00" value={this.state.account_monthly_interest_rate} onChange={this.handleaccount_monthly_interest_rateChange} />
            </FormItem>

            <FormItem label="Account Statement Fee">
              <Input name="account_statement_fee"  placeholder="0.00" value={this.state.account_statement_fee} onChange={this.handleaccount_statement_feeChange} />
            </FormItem>

            <FormItem label="Cheque Deposit Fee"
            >
              <Input name="cheque_deposit_fee"  placeholder="0.00" value={this.state.cheque_deposit_fee} onChange={this.handlecheque_deposit_feeChange} />
            </FormItem>

            <FormItem label="Reactivation Charge">
              <Input name="reactivation_charge"  placeholder="0.00" value={this.state.reactivation_charge} onChange={this.handlereactivation_chargeChange} />
            </FormItem>

            <FormItem label="Number of days for account to become dormant since last transaction"
            >
              <Input name="number_ofday_todormancy_sincelasttransaction"  placeholder="0.00" value={this.state.number_ofday_todormancy_sincelasttransaction} onChange={(val)=>{this.setState({number_ofday_todormancy_sincelasttransaction:val.target.value})}} />
            </FormItem>

            <FormItem label="Age limit (days)"
            >
              <Input name="agelimit_months_cap"  placeholder="number of days" value={this.state.agelimit_months_cap} onChange={(val)=>{this.setState({agelimit_months_cap:val.target.value})}} />
            </FormItem>

            <FormItem label="Member registration fees"      
            >
              <Input name="member_registrationfees"  placeholder="Member registration fees" value={this.state.member_registrationfees} onChange={(val)=>{this.setState({member_registrationfees:val.target.value})}} />
            </FormItem>

            <FormItem label="Minimum shares for account activation"   
            >
              <Input name="minimum_sharesforactivation"  placeholder="Minimum shares for member activation" value={this.state.minimum_sharesforactivation} onChange={(val)=>{this.setState({minimum_sharesforactivation:val.target.value})}} />
            </FormItem>
            
            <FormItem>
                <Button  type="primary" htmlType="submit">Update</Button>
            </FormItem>
            
            </Form>
          
          </Card>
                
          </div>
            )

          }

    }
}

export default AccountTypeDetail; 