import React from 'react';
import MemberForm from '../components/MemberForm'
import GroupForm from '../components/GroupForm'

import ActivateMemberForm from '../components/ActivateMemberForm'
import MemberImportForm from '../components/MemberImportForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin, Card,Form,DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,DownloadOutlined,EditOutlined,SwapOutlined,WalletFilled,LoadingOutlined,UnorderedListOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from 'moment';

var CryptoJS = require("crypto-js");

const dateFormat = 'DD/MM/YYYY';
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
const { RangePicker } = DatePicker;



function callback(key) {
  console.log(key);
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var sacco= ''
var bizuserid= ''
var bizuserid= ''
var token= ''

class MemberList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    members: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    userrights:{},
    groupconvermodal_visible:false,
    selected_member:'',
    dateone:'',
    datetwo:'',
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     }else{
        bizuserid= ''
     }
    
     if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   this.componentmountactions();

}

//component mount method
componentmountactions=()=>{
  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
  .then(res => {
      this.setState({
        members:res.data
      })

  })

  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
      this.setState({datarequested:false})
  })


}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    let form_data1 = new FormData();
    form_data1.append('memberid', id);
    form_data1.append('sacco', sacco);

    axios.post(`${serverconfig.backendserverurl}/customqueries/deletemember`, form_data1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      }).then(res =>{
      message.info("successfully deleted") 
      this.componentmountactions();
     }
     );

  };

    //open mdi guarantor form
    openmemberactivationform=(val)=>{
      this.setState({selectedmemberid:val})
      this.setState({memberactivationformvisible:true})
    }
  
    closememberactivationform=()=>{
      this.setState({memberactivationformvisible:false})
    }


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

      var columns = [
        {
          title: 'Member type',
          dataIndex: 'membertype',
          key: 'id',
          ...this.getColumnSearchProps('membertype'),
        },
        {
          title: 'Account Number',
          dataIndex: 'AccountNumber',
          key: 'id',
          ...this.getColumnSearchProps('AccountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'FullName',
          key: 'id',
          ...this.getColumnSearchProps('FullName'),
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          key: 'id',
          ...this.getColumnSearchProps('gender'),
        },
        {
          title: 'Account Type',
          dataIndex: 'accounttypename',
          key: 'id',
          ...this.getColumnSearchProps('accounttypename'),
        },
        {
          title: 'Account Contact',
          dataIndex: 'RegisteredPhoneNumber1',
          key: 'id',
          ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
        },
        {
          title: 'Email Address',
          dataIndex: 'EmailAddress',
          key: 'id',
          ...this.getColumnSearchProps('EmailAddress'),
          render:(text,record)=>
          <div>
          <p>{text}</p>
          <p>Email receiver: {record.email_statements===true?"Yes":"No"}</p>
          </div>

        },
        {
          title: 'Account Status',
          dataIndex: 'account_status',
          key: 'id',
          ...this.getColumnSearchProps('account_status'),
        },
        {
          title: 'User',
          dataIndex: 'username',
          key: 'id',
          ...this.getColumnSearchProps('username'),
        },
        {
          title: 'Date of joining',
          dataIndex: 'DateOfJoining',
          key: 'id',
          ...this.getColumnSearchProps('DateOfJoining'),
        },
        {
          title: 'Savings',
          dataIndex: 'UserBalance',
          key: 'id',
          ...this.getColumnSearchProps('UserBalance'),
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Shares',
          dataIndex: 'sharesnumber_balance',
          key: 'id',
          ...this.getColumnSearchProps('sharesnumber_balance'),
        },
        {
          title: 'Loan',
          dataIndex: 'loan_balance',
          key: 'id',
          ...this.getColumnSearchProps('loan_balance'),
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />

        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/members/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;

          {
            this.state.userrights.accountactivation?
            <Popover content={<p>Click here to change account status</p>} title="Change status">
            <EditOutlined style={{color:'green'}} onClick={()=>{this.openmemberactivationform(text)}}/>
            </Popover>
            :
            null
          }

          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to go to quick menu</p>} title="Quick menu">
            <Link to={`/quickmenu/`}>
            <UnorderedListOutlined style={{color:'blue'}}/>
            </Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;

          <Popover content={<p>Click here to delete member</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
            <DeleteOutlined style={{color:'red'}}/>
          </Popconfirm>
          </Popover>
          &nbsp;&nbsp;

          {
            record.groupAccount===false?
            <Popover content={<p>Click here to convert to group account</p>} title="Convert to group">
              <SwapOutlined 
                onClick={()=>{
                  this.setState({selected_member:record.id})
                  this.setState({groupconvermodal_visible:true})

              }}
              style={{color:'green'}}/>
            </Popover>
            :
            null
          }
          </p>,
        }
      ];
    
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>AVAILABLE MEMBER ACCOUNTS</h4>} key="1">
            
               <Form layout="inline" >
                <FormItem label="Date of joining">
                <RangePicker defaultValue={moment()} 
                  onChange={(date, dateString) =>{
                  this.setState({ dateone: dateString[0]});
                  this.setState({ datetwo: dateString[1]});
                  }} format={dateFormat} />
                </FormItem>

                <FormItem>
                <Button 
                onClick={()=>{
                  let form_data = new FormData();
                  form_data.append('dateone', this.state.dateone);
                  form_data.append('datetwo', this.state.datetwo);
                  form_data.append('backend_url',serverconfig.backendserverurl);
                  form_data.append('sacco', sacco);
                  
                  if(this.state.dateone===''||this.state.datetwo===''){
                    alert("Please select dates")
                  }else{
                     this.setState({members:[]})

                      this.setState({datarequested:true})
              
                      //Now submit sale data to database
                      axios.post(serverconfig.backendserverurl+'/customqueries/getmembersby_date', form_data,{
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                      .then(res =>{
                        this.setState({datarequested:false})
                        this.setState({members:JSON.parse(res.data.report)})
                      } 
                      )
                      .catch(error => console.log(error)) 
                    }  

                }} type="primary" htmlType="button">Search by date of joining</Button>
                </FormItem> 

                <ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>}>Download Excel</Button>}>
                <ExcelSheet data={this.state.members} name="Member Balances">
                    <ExcelColumn label="Acc No" value="AccountNumber"/>
                    <ExcelColumn label="Acc Name" value="FullName"/>
                    <ExcelColumn label="Savings" value="UserBalance"/>
                    <ExcelColumn label="Shares number" value="sharesnumber_balance"/>
                    <ExcelColumn label="Shares amount" value="sharesamount_balance"/>
                    <ExcelColumn label="Loan" value="loan_balance"/>
                    <ExcelColumn label="Member type" value="membertype"/>

                </ExcelSheet>
               </ExcelFile>

            </Form>

            <Table   
            columns={columns} 
            scroll={{ x: 1000 }}
            dataSource={this.state.members} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered/>
            <br />



            </Panel>
            </Collapse>
            <br></br>
            {
              this.state.userrights.can_register_members?
              <Row>
              <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
              <Collapse defaultActiveKey={['0']} onChange={callback}>
              <Panel header="Collapse Panel to open Single Member Form" key="1">
                <h2>Create new member here</h2>
                <MemberForm 
                requestType="post"
                expenseID={null}
                btnText="Create"
                /> 
              </Panel>
              </Collapse>  
                </Col>

               <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
                <Card>
                <h4 style={{fontWeight:'bolder'}}>Upload members data here</h4>
                <MemberImportForm /> 
                </Card>
              </Col> 
              </Row>
              :
              null
            }

            <Modal              
              visible={this.state.memberactivationformvisible}
              title={"Account status Form"}
              onCancel={this.closememberactivationform}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={this.closememberactivationform}>
                  Cancel
                </Button>
                ]}
                >
                <ActivateMemberForm memberid={this.state.selectedmemberid} />

            </Modal> 

            <Modal              
              visible={this.state.groupconvermodal_visible}
              title={"Group conversion Form"}
              onCancel={()=>{this.setState({groupconvermodal_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({groupconvermodal_visible:false})}}>
                  Cancel
                </Button>
                ]}
                >
                  <h4>Click to convert to group account</h4>
                  <Button type='primary' onClick={()=>{
                    this.setState({datarequested:true})
                    
                  let form_data1 = new FormData();
                  form_data1.append('account', this.state.selected_member);
                  axios.post(serverconfig.backendserverurl+'/customqueries/convertaccountto_group_joint', form_data1, {
                    headers: {
                      'content-type': 'multipart/form-data'
                    }
                    })
                    .then(res => {
                    this.setState({datarequested:false})
                    window.location.reload(false)
                    this.setState({groupconvermodal_visible:false})
                  })
                  .catch(error => console.log(error))

                  }}> Convert</Button>

            </Modal> 

        </div>
    )

    }

  }
}

export default MemberList; 
